import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { config } from '../config';

interface LoginState {
  from?: string;
}

function Login(): JSX.Element {
  const location = useLocation<LoginState>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    try {
      const { data } = await axios.post(`${config.backendUri}/api/token/`, {
        username,
        password,
      });
      const { access, refresh } = data;
      localStorage.setItem('access', access);
      localStorage.setItem('refresh', refresh);

      window.location.href = location.state?.from || '/';
    } catch (e) {
      alert('아이디나 비밀번호를 다시 확인해주세요.');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            <TextField
              label="이메일"
              type="email"
              value={username}
              onChange={handleUsernameChange}
              autoFocus
            />
          </Box>

          <Box mb={1}>
            <TextField
              label="패스워드"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Box>

          <Button variant="contained" color="primary" type="submit">
            로그인
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default Login;
