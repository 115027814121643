import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { config } from '../../../config';
import { Trader } from '../../../types/Report';
import { useListStyles } from '../../../styles/list';

interface WithholdingTax {
  id: number;
  issued: string;
  personnel: number;
  amount: number;
}

interface WithholdingTaxProps {
  trader: Trader;
  type: string;
}

function WithholdingTax(props: WithholdingTaxProps): JSX.Element {
  const { trader, type } = props;
  const [taxes, setTaxes] = useState<WithholdingTax[]>([]);
  const classes = useListStyles();

  const start_date =
    type === 'vat' ? trader.vat_start_date : trader.income_start_date;
  const end_date =
    type === 'vat' ? trader.vat_end_date : trader.income_end_date;

  useEffect(() => {
    axios
      .get(`${config.backendUri}/api/v2/withholding-taxes/`, {
        params: {
          hometax__trader: trader.id,
          issued__gte: `${start_date}`,
          issued__lte: `${end_date}`,
        },
      })
      .then(({ data }) => {
        setTaxes(data.results);
      });
  }, [trader.id, start_date, end_date]);

  return (
    <Container>
      <Box my={2}>
        <List>
          <Paper>
            <li>
              <ul className={classes.noPadding}>
                {taxes.map(({ id, issued, personnel, amount }) => (
                  <ListItem key={id}>
                    <ListItemText
                      primary={`총 ${personnel}명`}
                      secondary={issued}
                      className={classes.caption}
                    />
                    <ListItemText
                      primary={amount.toLocaleString() + '원'}
                      className={classes.price}
                    />
                  </ListItem>
                ))}
              </ul>
            </li>
          </Paper>
        </List>
      </Box>
    </Container>
  );
}

export default WithholdingTax;
