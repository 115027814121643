import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { Account, ORGANIZATIONS } from '../../../types/Account';

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
  },
}));

interface CardProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (
    business_type: string,
    organization: string,
    client_type: string,
    login_type: string,
    username: string,
    password: string,
    callback: (successful: boolean) => void
  ) => void;
  cards: Account[];
}

export default function Card(props: CardProps): JSX.Element {
  const { open, setOpen, onSubmit, cards } = props;
  const classes = useStyles();
  const [organization, setOrganization] = useState<string>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <Dialog fullScreen open={open}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="primary"
            onClick={(): void => {
              if (organization) {
                setOrganization(undefined);
              } else {
                setOpen(false);
              }
              setUsername('');
              setPassword('');
            }}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            className={classes.title}
            variant="h6"
            color="textPrimary"
          >
            {organization
              ? ORGANIZATIONS.find((o) => o.key === organization)!.label
              : '카드사'}
          </Typography>
        </Toolbar>
      </AppBar>

      {!organization && (
        <List>
          {ORGANIZATIONS.map(({ key, label }) => (
            <ListItem
              button
              key={key}
              onClick={(): void => setOrganization(key)}
              disabled={
                !!cards.find(({ organization }) => organization === key)
              }
            >
              <ListItemText>{label}</ListItemText>
            </ListItem>
          ))}
        </List>
      )}

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        p={1}
      >
        {organization && (
          <form
            onSubmit={(event): void => {
              event.preventDefault();
              setLoading(true);
              onSubmit(
                'CD',
                organization,
                'A',
                '1',
                username,
                password,
                (successful: boolean) => {
                  if (successful) {
                    setOrganization(undefined);
                    setUsername('');
                    setPassword('');
                    setOpen(false);
                  }
                  setLoading(false);
                }
              );
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="아이디"
                  value={username}
                  onChange={(e): void => setUsername(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="패스워드"
                  type="password"
                  value={password}
                  onChange={(e): void => setPassword(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size="1.5rem" /> : '추가'}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </Dialog>
  );
}
