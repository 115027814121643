import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface ListProps {
  column?: boolean;
  children: JSX.Element;
}

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function ListBox(props: ListProps): JSX.Element {
  const { column, children } = props;
  const classes = useStyle();

  return (
    <Box
      className={classes.root}
      style={{ flexDirection: column ? 'column' : 'row' }}
    >
      {children}
    </Box>
  );
}
