import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import Home from './Home/Home';
import Report from './Report/Report';
export default function Dashboard(): JSX.Element {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}income-tax/:signature`} component={Report} />
      <PrivateRoute path={`${match.url}:traderId?`} component={Home} />
    </Switch>
  );
}
