export const ORGANIZATIONS = [
  {
    key: '0301',
    label: 'KB카드',
  },
  {
    key: '0302',
    label: '현대카드',
  },
  {
    key: '0303',
    label: '삼성카드',
  },
  {
    key: '0304',
    label: 'NH카드',
  },
  {
    key: '0305',
    label: '비씨카드',
  },
  {
    key: '0306',
    label: '신한카드',
  },
  {
    key: '0309',
    label: '우리카드',
  },
  {
    key: '0311',
    label: '롯데카드',
  },
  {
    key: '0313',
    label: '하나카드',
  },
];

export interface Account {
  id: number;
  business_type: string;
  organization: string;
  client_type: string;
  login_type: string;
  status: string;
  message: string;
}
