import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  fab: {
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'flex-end',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export interface Paginator<T> {
  results: T[];
}
