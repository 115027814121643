import React, { useMemo, useRef } from 'react';
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Report } from '../../../types/Report';
import { ReactComponent as Logo } from '../logo-blue.svg';

const useStyle = makeStyles(() => ({
  amount: {
    boxShadow: 'inset 0 -10px 0 #b4e7f8',
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  footer: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: theme.palette.common.black,
  },
}))(TableCell);

interface CorporateTaxProps {
  report: Report;
}

function CorporateTax(props: CorporateTaxProps): JSX.Element {
  const { report } = props;
  const 법인세Ref = useRef<HTMLDivElement>(null);
  const 당기순이익Ref = useRef<HTMLDivElement>(null);
  const classes = useStyle();

  const 매출액 = useMemo(() => {
    return parseInt(report.parsed.법인세과세표준및세액신고서!.수입금액 || '0');
  }, [report]);

  const 매출원가 = useMemo(() => {
    const 매출원가 = report.parsed.표준손익계산서!.find(
      ({ 계정과목코드 }) => 계정과목코드 === '230009'
    );

    if (!매출원가) {
      return 0;
    }

    return parseInt(매출원가.금액);
  }, [report]);

  const 매출총손익 = useMemo(() => {
    const 매출총손익 = report.parsed.표준손익계산서!.find(
      ({ 계정과목코드 }) => 계정과목코드 === '230020'
    );

    if (!매출총손익) {
      return 0;
    }

    return parseInt(매출총손익.금액);
  }, [report]);

  const 판매비와관리비 = useMemo(() => {
    const 판매비와관리비 = report.parsed.표준손익계산서!.find(
      ({ 계정과목코드 }) => 계정과목코드 === '230021'
    );

    if (!판매비와관리비) {
      return 0;
    }

    return parseInt(판매비와관리비.금액);
  }, [report]);

  const 영업손익 = useMemo(() => {
    const 영업손익 = report.parsed.표준손익계산서!.find(
      ({ 계정과목코드 }) => 계정과목코드 === '230044'
    );

    if (!영업손익) {
      return 0;
    }

    return parseInt(영업손익.금액);
  }, [report]);

  const 영업외수익 = useMemo(() => {
    const 영업외수익 = report.parsed.표준손익계산서!.find(
      ({ 계정과목코드 }) => 계정과목코드 === '230045'
    );

    if (!영업외수익) {
      return 0;
    }

    return parseInt(영업외수익.금액);
  }, [report]);

  const 영업외비용 = useMemo(() => {
    const 영업외비용 = report.parsed.표준손익계산서!.find(
      ({ 계정과목코드 }) => 계정과목코드 === '230069'
    );

    if (!영업외비용) {
      return 0;
    }

    return parseInt(영업외비용.금액);
  }, [report]);

  const 법인세비용 = useMemo(() => {
    const 법인세비용 = report.parsed.표준손익계산서!.find(
      ({ 계정과목코드 }) => 계정과목코드 === '230094'
    );

    if (!법인세비용) {
      return 0;
    }

    return parseInt(법인세비용.금액);
  }, [report]);

  const 당기순이익 = useMemo(() => {
    const 당기순이익 = report.parsed.표준손익계산서!.find(
      ({ 계정과목코드 }) => 계정과목코드 === '230095'
    );

    if (!당기순이익) {
      return 0;
    }

    return parseInt(당기순이익.금액);
  }, [report]);

  const 과세표준 = useMemo(() => {
    return parseInt(report.parsed.법인세과세표준및세액신고서!.과세표준_계);
  }, [report]);

  const 산출세액 = useMemo(() => {
    return parseInt(report.parsed.법인세과세표준및세액신고서!.산출세액_계);
  }, [report]);

  const 총부담세액 = useMemo(() => {
    return parseInt(report.parsed.법인세과세표준및세액신고서!.총부담세액_계);
  }, [report]);

  const 공제감면세액 = useMemo(() => {
    return 산출세액 - 총부담세액 > 0 ? 산출세액 - 총부담세액 : 0;
  }, [산출세액, 총부담세액]);

  const 기납부세액 = useMemo(() => {
    return parseInt(report.parsed.법인세과세표준및세액신고서!.기납부세액_계);
  }, [report]);

  const 법인세 = useMemo(() => {
    return parseInt(
      report.parsed.법인세과세표준및세액신고서!.차감납부세액 || '0'
    );
  }, [report]);

  const 농특세 = useMemo(() => {
    return parseInt(
      report.parsed.농어촌특별세과세표준및세액조정계산서!.세액 || '0'
    );
  }, [report]);

  const 납부할세액 = useMemo(() => {
    return 법인세 + 농특세;
  }, [법인세, 농특세]);

  const period = useMemo(() => {
    if (!report.period_begin) {
      return '';
    }

    return `${report.period_begin} ~ ${report.period_end}`;
  }, [report]);

  return (
    <>
      <Box mb={2}>
        <Card>
          <CardHeader
            avatar={<Logo />}
            title="법인세 결과 안내문"
            subheader={period}
          />

          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
              {report.traders[0].business_name}에서 {report.due_date}까지 납부할
              금액은{' '}
              <span className={classes.amount}>
                {납부할세액.toLocaleString()}원
              </span>
              입니다.
            </Typography>
            <Typography variant="body1">
              지방소득세는 별도로 발생합니다.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              신고금액을 확인하신 후 신고확정 버튼을 눌러주세요.
            </Typography>

            <Table>
              <TableBody>
                <TableRow
                  hover
                  onClick={(): void => {
                    법인세Ref.current!.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <StyledTableCell>
                    <Badge badgeContent="?" color="primary">
                      법인세&nbsp;&nbsp;&nbsp;
                    </Badge>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {법인세.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>농특세</StyledTableCell>
                  <StyledTableCell>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" mr={0.5}>
                        <AddCircleOutlineIcon fontSize="small" />
                      </Box>
                      <Box display="flex" alignItems="center" fontSize="small">
                        {농특세.toLocaleString()}원
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              </TableBody>

              <TableFooter>
                <TableRow>
                  <StyledTableCell>납부할 세액</StyledTableCell>
                  <StyledTableCell align="right">
                    {납부할세액.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>지방소득세</StyledTableCell>
                  <StyledTableCell align="right">추가 안내</StyledTableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </Card>
      </Box>

      <Box mb={2}>
        <Typography variant="h6" ref={법인세Ref}>
          법인세
        </Typography>
        <Card>
          <CardContent>
            <Table>
              <TableBody>
                <TableRow
                  hover
                  onClick={(): void => {
                    당기순이익Ref.current!.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <StyledTableCell>
                    <Badge badgeContent="?" color="primary">
                      당기순이익&nbsp;&nbsp;&nbsp;
                    </Badge>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {당기순이익.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>과세표준</StyledTableCell>
                  <StyledTableCell align="right">
                    {과세표준.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>세율</StyledTableCell>
                  {당기순이익 <= 200000000 && (
                    <StyledTableCell align="right">10%</StyledTableCell>
                  )}
                  {당기순이익 > 200000000 && (
                    <StyledTableCell align="right">20%</StyledTableCell>
                  )}
                </TableRow>

                <TableRow>
                  <StyledTableCell>산출세액</StyledTableCell>
                  <StyledTableCell align="right">
                    {산출세액.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>공제감면세액</StyledTableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" mr={0.5}>
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </Box>
                      <Box display="flex" alignItems="center" fontSize="small">
                        {공제감면세액.toLocaleString()}원
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>총부담세액</StyledTableCell>
                  <StyledTableCell align="right">
                    {총부담세액.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>기납부세액</StyledTableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" mr={0.5}>
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </Box>
                      <Box display="flex" alignItems="center" fontSize="small">
                        {기납부세액.toLocaleString()}원
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableFooter>
                <TableRow>
                  <StyledTableCell>법인세</StyledTableCell>
                  <StyledTableCell align="right">
                    {법인세.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </Card>
      </Box>

      <Box mb={8}>
        <Typography variant="h6" ref={당기순이익Ref}>
          당기순이익
        </Typography>
        <Card>
          <CardContent>
            <Table>
              <TableBody>
                <TableRow>
                  <StyledTableCell>매출액</StyledTableCell>
                  <StyledTableCell align="right">
                    {매출액.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>매출원가</StyledTableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" mr={0.5}>
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </Box>
                      <Box display="flex" alignItems="center" fontSize="small">
                        {매출원가.toLocaleString()}원
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>매출총손익</StyledTableCell>
                  <StyledTableCell align="right">
                    {매출총손익.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>판매비/관리비</StyledTableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" mr={0.5}>
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </Box>
                      <Box display="flex" alignItems="center" fontSize="small">
                        {판매비와관리비.toLocaleString()}원
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>영업손익</StyledTableCell>
                  <StyledTableCell align="right">
                    {영업손익.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>영업외수익</StyledTableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" mr={0.5}>
                        <AddCircleOutlineIcon fontSize="small" />
                      </Box>
                      <Box display="flex" alignItems="center" fontSize="small">
                        {영업외수익.toLocaleString()}원
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>영업외비용</StyledTableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" mr={0.5}>
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </Box>
                      <Box display="flex" alignItems="center" fontSize="small">
                        {영업외비용.toLocaleString()}원
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell>법인세비용</StyledTableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" mr={0.5}>
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </Box>
                      <Box display="flex" alignItems="center" fontSize="small">
                        {법인세비용.toLocaleString()}원
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableFooter>
                <TableRow>
                  <StyledTableCell>당기순이익</StyledTableCell>
                  <StyledTableCell align="right">
                    {당기순이익.toLocaleString()}원
                  </StyledTableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default CorporateTax;
