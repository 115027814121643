import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import { Trader } from '../../../types/Report';
import { useListStyles } from '../../../styles/list';
import { config } from '../../../config';

interface CashPurchaseReceipt {
  id: number;
  used_date: string;
  member_store_name: string;
  supply_value: number;
  vat: number;
}

interface CashPurchaseReceiptProps {
  trader: Trader;
  type: string;
}

function CashPurchaseReceipt(props: CashPurchaseReceiptProps): JSX.Element {
  const { trader, type } = props;
  const [receipts, setReceipts] = useState<CashPurchaseReceipt[]>([]);
  const [receiptsByDate, setReceiptsByDate] = useState<{
    [date: string]: CashPurchaseReceipt[];
  }>({});
  const classes = useListStyles();

  const start_date =
    type === 'vat' ? trader.vat_start_date : trader.income_start_date;
  const end_date =
    type === 'vat' ? trader.vat_end_date : trader.income_end_date;

  useEffect(() => {
    const receiptsByDate: { [date: string]: CashPurchaseReceipt[] } = {};
    for (const receipt of receipts) {
      const key = new Date(receipt.used_date).toLocaleDateString();
      const list = receiptsByDate[key] || [];
      list.push(receipt);
      receiptsByDate[key] = list;
    }
    setReceiptsByDate(receiptsByDate);
  }, [receipts]);

  useEffect(() => {
    axios
      .get(`${config.backendUri}/api/v2/cash-purchase-receipts/`, {
        params: {
          hometax__trader: trader.id,
          issue_date__gte: start_date,
          issue_date__lte: end_date,
        },
      })
      .then(({ data }) => {
        setReceipts(data.results);
      });
  }, [trader.id, start_date, end_date]);

  return (
    <Container>
      <Box my={2}>
        <List>
          <Paper>
            {Object.keys(receiptsByDate).map((date) => (
              <li key={date}>
                <ul className={classes.noPadding}>
                  <ListSubheader className={classes.subheader}>
                    <div className={classes.row}>
                      <ListItemText
                        primary={new Date(date).toLocaleDateString()}
                      />
                      <ListItemText
                        className={classes.price}
                        primary={
                          receiptsByDate[date]
                            .reduce(
                              (acc, receipt) => acc + receipt.supply_value,
                              0
                            )
                            .toLocaleString() + '원'
                        }
                        secondary={
                          receiptsByDate[date]
                            .reduce((acc, receipt) => acc + receipt.vat, 0)
                            .toLocaleString() + '원'
                        }
                      />
                    </div>
                  </ListSubheader>
                  {receiptsByDate[date].map(
                    ({ id, member_store_name, supply_value, vat }) => (
                      <ListItem key={id}>
                        <ListItemText
                          primary={member_store_name}
                          className={classes.caption}
                        />
                        <ListItemText
                          primary={supply_value.toLocaleString() + '원'}
                          secondary={vat.toLocaleString() + '원'}
                          className={classes.price}
                        />
                      </ListItem>
                    )
                  )}
                </ul>
              </li>
            ))}
          </Paper>
        </List>
      </Box>
    </Container>
  );
}

export default CashPurchaseReceipt;
