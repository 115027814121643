import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { config } from '../../../config';
import ListBox from '../../../components/ListBox';
import { NumberFormatCustom, useDebounce, Vat as VatModel } from './Tax';

const useStyle = makeStyles(() => ({
  amount: {
    boxShadow: 'inset 0 -10px 0 #b4e7f8',
  },
  noPadding: {
    padding: 0,
  },
}));

interface VatProps {
  traderId: number;
  vat: VatModel;
}

function Vat(props: VatProps): JSX.Element {
  const match = useRouteMatch();
  const { traderId, vat } = props;
  const classes = useStyle();
  const [기타매출, set기타매출] = useState(
    vat.기타매출 ? vat.기타매출.toString() : ''
  );
  const [기타매입, set기타매입] = useState(
    vat.기타매입 ? vat.기타매입.toString() : ''
  );
  const debounced기타매출 = useDebounce(기타매출, 500);
  const debounced기타매입 = useDebounce(기타매입, 500);

  const 매출 = useMemo(
    (): number =>
      vat.전자세금계산서매출 +
      vat.카드매출 +
      vat.현금영수증매출 +
      parseInt(기타매출 || '0'),
    [vat, 기타매출]
  );

  const 매입 = useMemo(
    (): number =>
      vat.전자세금계산서매입 +
      vat.전자계산서매입 +
      vat.카드매입 +
      vat.현금영수증매입 +
      parseInt(기타매입 || '0'),
    [vat, 기타매입]
  );

  const 매출세액 = useMemo(
    (): number =>
      vat.전자세금계산서매출세액 +
      vat.카드매출세액 +
      vat.현금영수증매출세액 +
      Math.floor(parseInt(기타매출 || '0') / 10),
    [vat, 기타매출]
  );

  const 매입세액 = useMemo(
    (): number =>
      vat.전자세금계산서매입세액 +
      vat.전자계산서매입세액 +
      vat.카드매입세액 +
      vat.현금영수증매입세액 +
      Math.floor(parseInt(기타매입 || '0') / 10),
    [vat, 기타매입]
  );

  const 부가가치세 = useMemo((): number => 매출세액 - 매입세액, [
    매출세액,
    매입세액,
  ]);

  useEffect(() => {
    const 기타매출 = parseInt(debounced기타매출 || '0');
    if (기타매출 === vat.기타매출) {
      return;
    }

    axios.patch(`${config.backendUri}/api/v2/traders/${traderId}/`, {
      other_vat_sales: 기타매출,
    });
  }, [traderId, vat, debounced기타매출]);

  useEffect(() => {
    const 기타매입 = parseInt(debounced기타매입 || '0');
    if (기타매입 === vat.기타매입) {
      return;
    }

    axios.patch(`${config.backendUri}/api/v2/traders/${traderId}/`, {
      other_vat_purchase: 기타매입,
    });
  }, [traderId, vat, debounced기타매입]);

  return (
    <Fragment>
      <Typography gutterBottom variant="h6" component="h2">
        {vat.business_name}의 예상 부가가치세는{' '}
        <span className={classes.amount}>{부가가치세.toLocaleString()}원</span>
        입니다.
        <Alert severity="success">
          예상세액은 실제 납부할 세액과 다를 수 있습니다. 기타 매출/매입을
          입력하여 더 정확한 예상 세금을 얻을 수 있습니다.
        </Alert>
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>매출</Typography>
            <Typography>{매출.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/vat-sales-tax-checks`}
              >
                <TableCell>전자세금계산서</TableCell>
                <TableCell align="right">
                  {vat.전자세금계산서매출.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/vat-card-sales-approvals`}
              >
                <TableCell>카드단말기</TableCell>
                <TableCell align="right">
                  {vat.카드매출.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/vat-cash-sales-receipts`}
              >
                <TableCell>현금영수증</TableCell>
                <TableCell align="right">
                  {vat.현금영수증매출.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>기타 매출</TableCell>
                <TableCell align="right">
                  <TextField
                    value={기타매출}
                    onChange={(event): void => {
                      const floatValue = parseInt(event.target.value);
                      if (floatValue) {
                        set기타매출(floatValue.toString());
                      } else {
                        set기타매출('');
                      }
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <ListBox>
                    <Fragment>
                      <Box>
                        <Chip
                          label={`${vat.start_date}~${vat.end_date}`}
                          size="small"
                          color="primary"
                          icon={<DateRangeIcon />}
                        />
                      </Box>
                      <ListBox>
                        <Fragment>
                          <Chip
                            label="기타 매출"
                            variant="outlined"
                            color="primary"
                            size="small"
                          />
                          종이 세금계산서, 온라인, 현금 매출의 합계
                        </Fragment>
                      </ListBox>
                    </Fragment>
                  </ListBox>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>매입</Typography>
            <Typography>{매입.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/vat-purchase-tax-checks`}
              >
                <TableCell>전자세금계산서</TableCell>
                <TableCell align="right">
                  {vat.전자세금계산서매입.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/vat-purchase-checks`}
              >
                <TableCell>전자계산서</TableCell>
                <TableCell align="right">
                  {vat.전자계산서매입.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/vat-card-approvals`}
              >
                <TableCell>사업용 신용카드</TableCell>
                <TableCell align="right">
                  {vat.카드매입.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/vat-cash-purchase-receipts`}
              >
                <TableCell>현금영수증</TableCell>
                <TableCell align="right">
                  {vat.현금영수증매입.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>기타 매입</TableCell>
                <TableCell align="right">
                  <TextField
                    value={기타매입}
                    onChange={(event): void => {
                      const floatValue = parseInt(event.target.value);
                      if (floatValue) {
                        set기타매입(floatValue.toString());
                      } else {
                        set기타매입('');
                      }
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <ListBox>
                    <Fragment>
                      <Box>
                        <Chip
                          label={`${vat.start_date}~${vat.end_date}`}
                          size="small"
                          color="primary"
                          icon={<DateRangeIcon />}
                        />
                      </Box>
                      <ListBox>
                        <Fragment>
                          <Chip
                            label="기타 매입"
                            variant="outlined"
                            color="primary"
                            size="small"
                          />
                          종이 세금계산서 매입의 합계
                        </Fragment>
                      </ListBox>
                    </Fragment>
                  </ListBox>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>매출세액</Typography>
            <Typography>{매출세액.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>매출</TableCell>
                <TableCell align="right">{매출.toLocaleString()}원</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>세율</TableCell>
                <TableCell align="right">약 10%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>매입세액</Typography>
            <Typography>{매입세액.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>매입</TableCell>
                <TableCell align="right">{매입.toLocaleString()}원</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>세율</TableCell>
                <TableCell align="right">약 10%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded>
        <AccordionSummary>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>부가가치세</Typography>
            <Typography>{부가가치세.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>매출세액</TableCell>
                <TableCell align="right">
                  {매출세액.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>매입세액</TableCell>
                <TableCell align="right">
                  {(-매입세액).toLocaleString()}원
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}

export default Vat;
