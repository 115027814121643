import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { TokenProvider } from './components/TokenProvider';
import { Dashboard, Manager, Login } from './pages';
import PrivateRoute from './components/PrivateRoute';
import { config } from './config';
import themes from './theme';

Sentry.init({
  dsn: "https://7bbc07fb610c11c07db7cd4627208016@o191554.ingest.sentry.io/4506629991694336",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/mobiletax\.kr/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const cache = new InMemoryCache();

const link = new WebSocketLink({
  uri: config.graphqlUri,
  options: {
    reconnect: true,
  },
});

const client = new ApolloClient({
  cache,
  link,
});

function App(): JSX.Element {
  return (
    <ApolloProvider client={client}>
      <TokenProvider>
        <BrowserRouter basename="/app">
          <ThemeProvider theme={themes.light}>
            <CssBaseline>
              <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/manager" component={Manager} />
                <Route component={Dashboard} />
              </Switch>
            </CssBaseline>
          </ThemeProvider>
        </BrowserRouter>
      </TokenProvider>
    </ApolloProvider>
  );
}

export default App;
