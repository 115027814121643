import React, { Fragment } from 'react';
import { Checkbox, Chip, TableCell, TableRow } from '@material-ui/core';
import { Report } from '../../../types/Report';
import ListBox from '../../ListBox';
import { useStyle } from './common';

interface ReportTableRowProps {
  report: Report;
  onSelect: (reportId: number) => void;
  checked: boolean;
  selectable?: boolean;
  fields?: string[];
}

export default function ReportTableRow(
  props: ReportTableRowProps
): JSX.Element {
  const { report, onSelect, checked, selectable, fields } = props;
  const classes = useStyle();

  let 세액 = 0;
  if (report.parsed.세액의계산) {
    세액 = parseInt(report.parsed.세액의계산.종합소득세_납부할총세액 || '0');
  }
  if (report.parsed.일반과세자신고서) {
    세액 = parseInt(report.parsed.일반과세자신고서.차감납부할세액);
  }
  if (report.parsed.간이과세자신고서) {
    세액 = parseInt(report.parsed.간이과세자신고서.차감납부할세액);
  }

  return (
    <TableRow hover onClick={(): void => onSelect(report.id)}>
      {selectable !== false && (
        <TableCell padding="checkbox">
          <Checkbox checked={checked} />
        </TableCell>
      )}
      <TableCell className={classes.nameColumn}>
        <a
          href={`/reports/${report.id}/`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {report.name}
        </a>
      </TableCell>
      <TableCell>
        <ListBox>
          <Fragment>
            {report.traders.map((t) => {
              let label = t.business_name;
              if (t.accounting_manager) {
                label += `: ${t.accounting_manager.name}`;
              }

              return (
                <Chip
                  key={t.id}
                  variant="outlined"
                  size="small"
                  label={label}
                />
              );
            })}
          </Fragment>
        </ListBox>
      </TableCell>
      <TableCell align="right">{세액.toLocaleString()}원</TableCell>
      {fields &&
        fields.indexOf('note') !== -1 &&
        ((): JSX.Element => {
          return (
            <TableCell>
              <ListBox>
                <Fragment>
                  {report.has_co_invester && (
                    <Chip variant="outlined" size="small" label="공동사업자" />
                  )}
                  {report.trader_count === 0 && (
                    <Chip
                      variant="outlined"
                      size="small"
                      label="해피택스 미사용"
                    />
                  )}
                  {report.trader_count > 1 && (
                    <Chip variant="outlined" size="small" label="복수사업자" />
                  )}
                </Fragment>
              </ListBox>
            </TableCell>
          );
        })()}
      {fields && fields.indexOf('confirmed') !== -1 && (
        <TableCell>{report.confirmed ? '확정' : '미확정'}</TableCell>
      )}
      {fields && fields.indexOf('actions') !== -1 && <TableCell></TableCell>}
    </TableRow>
  );
}
