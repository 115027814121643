import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import { config } from '../../../config';
import { Trader } from '../../../types/Report';
import { useListStyles } from '../../../styles/list';

interface CardSalesApproval {
  id: number;
  used_date: string;
  used_amount: number;
  card_company: string;
  card_no: string;
}

interface CardSalesApprovalProps {
  trader: Trader;
  type: string;
}

function CardSalesApproval(props: CardSalesApprovalProps): JSX.Element {
  const { trader, type } = props;
  const [approvals, setApprovals] = useState<CardSalesApproval[]>([]);
  const [approvalsByDate, setApprovalsByDate] = useState<{
    [date: string]: CardSalesApproval[];
  }>({});
  const classes = useListStyles();

  const start_date =
    type === 'vat' ? trader.vat_start_date : trader.income_start_date;
  const end_date =
    type === 'vat' ? trader.vat_end_date : trader.income_end_date;

  useEffect(() => {
    const approvalsByDate: { [date: string]: CardSalesApproval[] } = {};
    for (const approval of approvals) {
      const key = new Date(approval.used_date).toLocaleDateString();
      const list = approvalsByDate[key] || [];
      list.push(approval);
      approvalsByDate[key] = list;
    }
    setApprovalsByDate(approvalsByDate);
  }, [approvals]);

  useEffect(() => {
    axios
      .get(`${config.backendUri}/api/v2/card-sales-approvals/`, {
        params: {
          card_sales__trader: trader.id,
          used_date__gte: `${start_date} 00:00:00`,
          used_date__lte: `${end_date} 23:59:59`,
        },
      })
      .then(({ data }) => {
        setApprovals(data.results);
      });
  }, [trader.id, start_date, end_date]);

  return (
    <Container>
      <Box my={2}>
        <List>
          <Paper>
            {Object.keys(approvalsByDate).map((date) => (
              <li key={date}>
                <ul className={classes.noPadding}>
                  <ListSubheader className={classes.subheader}>
                    <div className={classes.row}>
                      <ListItemText primary={date} />
                      <ListItemText
                        className={classes.price}
                        primary={
                          Math.floor(
                            (approvalsByDate[date].reduce(
                              (acc, cardSalesApproval) =>
                                acc + cardSalesApproval.used_amount,
                              0
                            ) *
                              10) /
                              11
                          ).toLocaleString() + '원'
                        }
                        secondary={
                          Math.floor(
                            approvalsByDate[date].reduce(
                              (acc, cardSalesApproval) =>
                                acc + cardSalesApproval.used_amount,
                              0
                            ) / 11
                          ).toLocaleString() + '원'
                        }
                      />
                    </div>
                  </ListSubheader>
                  {approvalsByDate[date].map(
                    ({ id, used_date, used_amount, card_company, card_no }) => (
                      <ListItem key={id}>
                        <ListItemText
                          primary={`${card_company} ${card_no}`}
                          secondary={new Date(used_date).toLocaleTimeString()}
                          className={classes.caption}
                        />
                        <ListItemText
                          primary={
                            Math.floor(
                              (used_amount * 10) / 11
                            ).toLocaleString() + '원'
                          }
                          secondary={
                            Math.floor(used_amount / 11).toLocaleString() + '원'
                          }
                          className={classes.price}
                        />
                      </ListItem>
                    )
                  )}
                </ul>
              </li>
            ))}
          </Paper>
        </List>
      </Box>
    </Container>
  );
}

export default CardSalesApproval;
