import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import Confirm from '../../Confirm';

interface EnhancedTableToolbarProps {
  title: string;
  numSelected: number;
  onSend: () => void;
  onDelete: () => void;
  selectable?: boolean;
}

export default function EnhancedTableToolbar(
  props: EnhancedTableToolbarProps
): JSX.Element {
  const [sendOpen, setSendOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { title, numSelected, onSend, onDelete, selectable } = props;

  return (
    <Toolbar>
      <Confirm
        title="정말 전송하시겠습니까?"
        description={`선택된 ${numSelected}개의 신고서가 전송됩니다.`}
        ok="전송"
        open={sendOpen}
        onOk={onSend}
        onClose={(): void => setSendOpen(false)}
      />
      <Confirm
        title="정말 삭제하시겠습니까?"
        description={
          <Fragment>
            선택된 {numSelected}개의 신고서가 삭제됩니다.
            <br />
            복구는 불가능하며 이미 발송된 신고서는 더 이상 열람이 불가능합니다.
          </Fragment>
        }
        ok="삭제"
        open={deleteOpen}
        onOk={onDelete}
        onClose={(): void => setDeleteOpen(false)}
      />

      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        {selectable !== false && numSelected ? (
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected}개 선택됨
          </Typography>
        ) : (
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        )}
        {selectable !== false && numSelected ? (
          <Box>
            <Tooltip title="Delete">
              <IconButton
                onClick={(): void => setDeleteOpen(true)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <Button
              onClick={(): void => setSendOpen(true)}
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
            >
              전송
            </Button>
          </Box>
        ) : null}
      </Box>
    </Toolbar>
  );
}
