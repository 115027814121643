import React, {
  useState,
  useRef,
  KeyboardEvent,
  useCallback,
  useEffect,
} from 'react';
import {
  Box,
  TextareaAutosize,
  makeStyles,
  Fab,
  Zoom,
} from '@material-ui/core';
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { Waypoint } from 'react-waypoint';
import ChatIcon from '@material-ui/icons/Chat';
import { MessageEdge } from '../../generated/graphql';
import { default as Chat } from './Chat';

interface MessageVariables {
  trader: string;
}

const GET_USER = gql`
  query GetUser {
    user {
      id
    }
  }
`;

const GET_MESSAGES = gql`
  query GetMessages($trader: ID!, $first: Int, $after: String) {
    messages(trader: $trader, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          content
          created
          sender {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const ON_MESSAGE_ADDED = gql`
  subscription OnMessageAdded($trader: ID!) {
    onMessageAdded(trader: $trader) {
      message {
        id
        content
        created
        sender {
          id
          name
        }
      }
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage($trader: ID!, $content: String!) {
    sendMessage(trader: $trader, content: $content) {
      ok
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: 'calc(100vh - 56px)',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      maxHeight: 'calc(100vh - 48px)',
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 64px)',
    },
    overflow: 'auto',
  },
  chatsContainer: {
    position: 'relative',
    maxWidth: '100%',
    overflowY: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '&> :first-child': {
      marginTop: 'auto',
    },
  },
  chats: {},
  chatBox: {
    padding: theme.spacing(1),
  },
  chat: {
    border: 'none',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    outline: 'none',
    resize: 'none',
    width: '100%',
  },
  fab: {
    position: 'absolute',
    bottom: '100%',
    right: theme.spacing(2),
  },
}));

interface ChatsProps {
  id: string;
}

export default function Chats(props: ChatsProps): JSX.Element {
  const { id } = props;
  const chatsRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLTextAreaElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [messageAdded, setMessageAdded] = useState(false);
  const [sendMessage] = useMutation(SEND_MESSAGE);
  const classes = useStyles();

  const { data: userData } = useQuery(GET_USER);

  const { data, loading } = useQuery(GET_MESSAGES, {
    variables: {
      trader: id,
      first: 20,
    },
    fetchPolicy: 'cache-and-network',
  });

  useSubscription(ON_MESSAGE_ADDED, {
    variables: {
      trader: id,
    },
    onSubscriptionData({ subscriptionData, client }) {
      if (
        !subscriptionData ||
        !subscriptionData.data ||
        !subscriptionData.data.onMessageAdded ||
        !subscriptionData.data.onMessageAdded.message
      ) {
        return;
      }

      const message = subscriptionData.data.onMessageAdded.message;
      const messages = data.messages;

      if (!messages) {
        return;
      }

      client.cache.writeQuery({
        query: GET_MESSAGES,
        data: {
          messages: {
            ...messages,
            edges: [message, ...messages.edges],
          },
        },
      });
    },
  });

  let messages: MessageEdge[] = [];
  if (data && data.messages) {
    messages = data.messages.edges;
  }

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        if (!e.shiftKey) {
          if (e.currentTarget.value.trim()) {
            sendMessage({
              variables: {
                trader: id,
                content: e.currentTarget.value.replace(/\n/g, '<br />'),
              },
            });
            e.currentTarget.value = '';
          }

          e.preventDefault();
          return;
        }
      }
    },
    [id, sendMessage]
  );

  useEffect(() => {
    if (!loading) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
    chatRef.current?.focus();
  }, [id, chatRef]);

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      <div ref={chatsRef} className={classes.chatsContainer}>
        {messages.map((chat: MessageEdge, index: number) => {
          const message = messages[messages.length - index - 1].node!;
          const continued = false;
          return (
            <Chat
              key={message.id}
              chat={message}
              me={message.sender.id === userData.user.id}
              continued={continued}
            />
          );
        })}
        <div ref={bottomRef}>
          <Waypoint
            onEnter={(): void => {
              setMessageAdded(false);
            }}
          />
        </div>
      </div>
      <Box p={1} position="relative">
        <Zoom in={messageAdded}>
          <Fab
            className={classes.fab}
            size="small"
            color="primary"
            onClick={(): void => {
              bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <ChatIcon />
          </Fab>
        </Zoom>
        <TextareaAutosize
          autoFocus
          rowsMin={2}
          className={classes.chat}
          onKeyPress={handleKeyPress}
          ref={chatRef}
        />
      </Box>
    </Box>
  );
}
