import React, { Fragment, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Report } from '../../../types/Report';
import { ReactComponent as Logo } from '../logo-blue.svg';

const useStyle = makeStyles(() => ({
  amount: {
    boxShadow: 'inset 0 -10px 0 #b4e7f8',
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

interface SimplifiedValueAddedTaxProps {
  report: Report;
}

export default function SimplifiedValueAddedTax(
  props: SimplifiedValueAddedTaxProps
): JSX.Element {
  const { report } = props;
  const {
    산출세액,
    매입세금계산서공제세액,
    공제세액,
    매입자납부특례기납부세액,
    예정고지세액,
    가산세액세액합계,
    차감납부할세액,
  } = report.parsed.간이과세자신고서 || {};
  const classes = useStyle();

  const period = useMemo(() => {
    if (!report.period_begin) {
      return '';
    }

    return `${report.period_begin} ~ ${report.period_end}`;
  }, [report]);

  return (
    <Fragment>
      <Box mb={1}>
        <Card>
          <CardHeader
            avatar={<Logo />}
            title="부가가치세 결과 안내문"
            subheader={period}
          />

          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
              {report.name} 대표님께서 내실 금액은{' '}
              <span className={classes.amount}>
                {parseInt(차감납부할세액 || '0').toLocaleString()}원
              </span>
              입니다.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              신고금액을 확인하신 후 신고확정 버튼을 눌러주세요.
            </Typography>

            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>산출세액</TableCell>
                  <TableCell align="right">
                    {parseInt(산출세액 || '0').toLocaleString()}원
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <RemoveCircleOutlineIcon fontSize="small" />
                      <small>
                        매입세금계산서
                        <br />
                        공제세액
                      </small>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {parseInt(매입세금계산서공제세액 || '0').toLocaleString()}원
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <RemoveCircleOutlineIcon fontSize="small" />
                      공제세액
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {(
                      parseInt(공제세액 || '0') -
                      parseInt(매입세금계산서공제세액 || '0')
                    ).toLocaleString()}
                    원
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <RemoveCircleOutlineIcon fontSize="small" />
                      <small>
                        매입자납부특례
                        <br />
                        기납부세액
                      </small>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {parseInt(매입자납부특례기납부세액 || '0').toLocaleString()}
                    원
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Box display="flex">
                      <RemoveCircleOutlineIcon fontSize="small" />
                      예정고지세액
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {parseInt(예정고지세액 || '0').toLocaleString()}원
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Box display="flex">
                      <AddCircleOutlineIcon fontSize="small" />
                      가산세액
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {parseInt(가산세액세액합계 || '0').toLocaleString()}원
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>부가가치세</TableCell>
                  <TableCell align="right">
                    <strong>
                      {parseInt(차감납부할세액 || '0').toLocaleString()}원
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
}
