import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(() => ({
  nameColumn: {
    width: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
