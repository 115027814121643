import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useMutation, gql } from '@apollo/client';
import { config } from '../config';

const LOGIN = gql`
  mutation Login($token: String!) {
    login(token: $token) {
      ok
    }
  }
`;

axios.interceptors.request.use((request) => {
  const access = localStorage.getItem('access');
  if (access) {
    request.headers.Authorization = `Bearer ${access}`;
  }

  return request;
});

interface Token {
  access?: string;
  exp?: number;
  loading?: boolean;
}

interface Jwt {
  token_type?: string;
  exp?: number;
  jti?: string;
  user_id?: number;
}

async function refresh(): Promise<string | null> {
  const refresh = localStorage.getItem('refresh');
  if (!refresh) {
    return null;
  }

  const { data } = await axios.post(`${config.backendUri}/api/token/refresh/`, {
    refresh,
  });

  const { access } = data;
  localStorage.setItem('access', access);
  return access;
}

function parseJwt(token: string | null): Jwt {
  if (!token) {
    return {};
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const TokenContext = createContext<[Token, (access: string) => void]>([
  { loading: true },
  // eslint-disable-next-line
  (access: string): void => {},
]);

export const useToken = (): [Token, (access: string) => void] =>
  useContext(TokenContext);

function checkValidation(token: Token): boolean {
  if (!token.exp) {
    return true;
  }

  return token.exp * 1000 - new Date().getTime() >= 60000;
}

function getCookie(key: string): string | null {
  const cookie = document.cookie
    .split(';')
    .find((cookie) => cookie.split('=')[0].trim() === key);

  if (!cookie) {
    return null;
  }

  return cookie.split('=')[1];
}

export function TokenProvider(props: React.PropsWithChildren<{}>): JSX.Element {
  const [access, setAccess] = useState<string | null | undefined>(undefined);
  const [token, setToken] = useState<Token>({
    loading: true,
  });
  const [login] = useMutation(LOGIN);

  useEffect(() => {
    const handle = setInterval(() => {
      if (checkValidation(token)) {
        return;
      }

      refresh().then((access) => {
        setAccess(access);
      });
    }, 3000);

    return (): void => {
      clearInterval(handle);
    };
  }, [token]);

  useEffect(() => {
    if (access === undefined) {
      return;
    }

    if (!access) {
      setToken({ loading: false });
      return;
    }

    const token = parseJwt(access);
    if (checkValidation(token)) {
      login({ variables: { token: access } }).then(({ data }) => {
        const { login } = data;
        if (!login.ok) {
          setToken({
            loading: false,
          });
          return;
        }

        setToken({
          loading: false,
          access,
          exp: token.exp,
        });
      });
    } else {
      refresh().then((access) => {
        setAccess(access);
      });
    }
  }, [access, login]);

  useEffect(() => {
    const access = localStorage.getItem('access');
    if (access) {
      setAccess(access);
      return;
    }

    if (getCookie('login')) {
      axios
        .post(`${config.backendUri}/api/token/`, null, {
          withCredentials: true,
        })
        .then(({ data }) => {
          const { access, refresh } = data;
          localStorage.setItem('access', access);
          localStorage.setItem('refresh', refresh);
          setAccess(access);
        })
        .catch(() => {
          setToken({ loading: false });
        });
      return;
    }

    setToken({ loading: false });
  }, []);

  return (
    <TokenContext.Provider value={[token, setAccess]}>
      {!token.loading && props.children}
    </TokenContext.Provider>
  );
}
