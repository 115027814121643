import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Alert } from '@material-ui/lab';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { config } from '../../../config';
import { Account } from '../../../types/Account';
import Card from './Card';
import Hometax from './Hometax';
import CardSales from './CardSales';

export const ORGANIZATIONS = [
  {
    key: '0301',
    label: 'KB카드',
  },
  {
    key: '0302',
    label: '현대카드',
  },
  {
    key: '0303',
    label: '삼성카드',
  },
  {
    key: '0304',
    label: 'NH카드',
  },
  {
    key: '0305',
    label: '비씨카드',
  },
  {
    key: '0306',
    label: '신한카드',
  },
  {
    key: '0309',
    label: '우리카드',
  },
  {
    key: '0311',
    label: '롯데카드',
  },
  {
    key: '0313',
    label: '하나카드',
  },
];

interface Hometax {
  id: number;
  username: string;
  status: string;
  message: string;
}

interface CardSales {
  id: number;
  status: string;
}

interface CredentialProps {
  traderId: number;
}

interface CredentialState {
  canGoBack?: boolean;
}

function Credential(props: CredentialProps): JSX.Element {
  const history = useHistory();
  const match = useRouteMatch();
  const { state } = useLocation<CredentialState>();
  const { traderId } = props;
  const [hometaxes, setHometaxes] = useState<Hometax[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [cardSales, setCardSales] = useState<CardSales[]>([]);
  const [hometaxOpen, setHometaxOpen] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);
  const [cardSalesOpen, setCardSalesOpen] = useState(false);

  useEffect(() => {
    if (match.url.includes('card-sales')) {
      setCardSalesOpen(true);
    } else if (match.url.includes('card')) {
      setCardOpen(true);
    } else if (match.url.includes('hometax')) {
      setHometaxOpen(true);
    } else {
      setCardSalesOpen(false);
      setCardOpen(false);
      setHometaxOpen(false);
    }
  }, [match.url]);

  const handleOpen = useCallback(
    (open, url) => {
      if (open) {
        history.push(`${match.url}/${url}`, {
          canGoBack: true,
        });
      } else {
        if (state && state.canGoBack) {
          history.goBack();
        } else {
          history.push('/');
        }
      }
    },
    [history, match.url, state]
  );

  const handleOpenHometax = useCallback(
    (open) => {
      handleOpen(open, 'hometax');
      setHometaxOpen(open);
    },
    [handleOpen]
  );

  const handleOpenCard = useCallback(
    (open) => {
      handleOpen(open, 'card');
      setCardOpen(open);
    },
    [handleOpen]
  );

  const handleOpenCardSales = useCallback(
    (open) => {
      handleOpen(open, 'card-sales');
      setCardSalesOpen(open);
    },
    [handleOpen]
  );

  const addHometax = useCallback(
    (
      loginType: string,
      username: string,
      password: string,
      callback: (successful: boolean) => void
    ) => {
      axios
        .post(`${config.backendUri}/api/v2/hometaxes/`, {
          trader: traderId,
          login_type: loginType,
          username,
          password,
        })
        .then(({ data }) => {
          setHometaxes([data, ...hometaxes]);
          callback(true);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            alert(response.data.message);
          } else {
            alert('알 수 없는 오류가 발생했습니다. 고객센터에 문의해주세요.');
          }
          callback(false);
        });
    },
    [traderId, hometaxes]
  );

  const addCard = useCallback(
    (
      business_type,
      organization,
      client_type,
      login_type,
      username,
      password,
      callback
    ) => {
      axios
        .post(`${config.backendUri}/api/v2/accounts/`, {
          trader: traderId,
          business_type,
          organization,
          client_type,
          login_type,
          username,
          password,
        })
        .then(({ data }) => {
          setAccounts([data, ...accounts]);
          callback(true);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            alert(response.data.message);
          } else {
            alert('알 수 없는 오류가 발생했습니다. 고객센터에 문의해주세요.');
          }
          callback(false);
        });
    },
    [traderId, accounts]
  );

  const addCardSales = useCallback(
    (
      username: string,
      password: string,
      callback: (successful: boolean) => void
    ) => {
      axios
        .post(`${config.backendUri}/api/v2/card-sales/`, {
          trader: traderId,
          username,
          password,
        })
        .then(({ data }) => {
          setCardSales([data, ...cardSales]);
          callback(true);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            alert(response.data.message);
          } else {
            alert('알 수 없는 오류가 발생했습니다. 고객센터에 문의해주세요.');
          }
          callback(false);
        });
    },
    [traderId, cardSales]
  );

  const deleteHometax = useCallback(
    (id) => {
      axios.delete(`${config.backendUri}/api/v2/hometaxes/${id}/`).then(() => {
        setHometaxes(hometaxes.filter((h) => h.id !== id));
      });
    },
    [hometaxes]
  );

  const deleteCard = useCallback(
    (id) => {
      axios.delete(`${config.backendUri}/api/v2/accounts/${id}/`).then(() => {
        setAccounts(accounts.filter((account) => account.id !== id));
      });
    },
    [accounts]
  );

  const deleteCardSales = useCallback(
    (id) => {
      axios.delete(`${config.backendUri}/api/v2/card-sales/${id}/`).then(() => {
        setCardSales(cardSales.filter((c) => c.id !== id));
      });
    },
    [cardSales]
  );

  const cards = useMemo(() => {
    return accounts.filter(({ business_type }) => business_type === 'CD');
  }, [accounts]);

  useEffect(() => {
    if (!traderId) {
      setAccounts([]);
      return;
    }

    axios
      .get(`${config.backendUri}/api/v2/traders/${traderId}/hometaxes/`)
      .then(({ data }) => {
        setHometaxes(data);
      });

    axios
      .get(`${config.backendUri}/api/v2/traders/${traderId}/accounts/`)
      .then(({ data }) => {
        setAccounts(data);
      });

    axios
      .get(`${config.backendUri}/api/v2/traders/${traderId}/card_sales/`)
      .then(({ data }) => {
        setCardSales(data);
      });
  }, [traderId]);

  return (
    <Fragment>
      <Container>
        <Box py={1}>
          <List>
            <Paper>
              <ListSubheader>
                홈택스
                <IconButton onClick={(): void => handleOpenHometax(true)}>
                  <AddCircleIcon />
                </IconButton>
              </ListSubheader>
              {hometaxes.map(({ id, username, status, message }) => (
                <Fragment key={id}>
                  <ListItem>
                    <ListItemText>
                      {username} {status === 'in progress' && '연동 중'}
                      {status === 'successful' && '연동 완료'}
                      {status === 'failed' && '연동 실패'}{' '}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={(): void => deleteHometax(id)}
                        edge="end"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {message && (
                    <Alert severity="error">
                      {message.replace(/{.*}/gi, '')}
                    </Alert>
                  )}
                </Fragment>
              ))}
            </Paper>
          </List>

          <List>
            <Paper>
              <ListSubheader>
                이용 중인 카드사
                <IconButton onClick={(): void => handleOpenCard(true)}>
                  <AddCircleIcon />
                </IconButton>
              </ListSubheader>
              {cards.map(({ id, organization, status, message }) => (
                <Fragment key={organization}>
                  <ListItem>
                    <ListItemText>
                      {
                        ORGANIZATIONS.find(({ key }) => key === organization)
                          ?.label
                      }{' '}
                      {status === 'in progress' && '연동 중'}
                      {status === 'successful' && '연동 완료'}
                      {status === 'failed' && '연동 실패'}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={(): void => deleteCard(id)}
                        edge="end"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {message && (
                    <Alert severity="error">
                      {message.replace(/{.*}/gi, '')}
                    </Alert>
                  )}
                </Fragment>
              ))}
            </Paper>
          </List>

          <List>
            <Paper>
              <ListSubheader>
                여신금융협회
                <IconButton onClick={(): void => handleOpenCardSales(true)}>
                  <AddCircleIcon />
                </IconButton>
              </ListSubheader>
              {cardSales.map(({ id, status }) => (
                <ListItem key={id}>
                  <ListItemText>
                    {status === 'initializing' ? '연동 중' : '연동 완료'}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={(): void => deleteCardSales(id)}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </Paper>
          </List>
        </Box>
      </Container>

      <Hometax
        open={hometaxOpen}
        setOpen={handleOpenHometax}
        onSubmit={addHometax}
      />
      <Card
        open={cardOpen}
        setOpen={handleOpenCard}
        onSubmit={addCard}
        cards={cards}
      />
      <CardSales
        open={cardSalesOpen}
        setOpen={handleOpenCardSales}
        onSubmit={addCardSales}
      />
    </Fragment>
  );
}

export default Credential;
