import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import { config } from '../../../config';
import { Trader } from '../../../types/Report';
import { useListStyles } from '../../../styles/list';

interface Check {
  id: number;
  issue_date: string;
  supplier_company_name: string;
  contractor_company_name: string;
  tax_amt: number;
  supply_value: number;
}

interface CheckProps {
  trader: Trader;
  type: string;
  inquiryType: string;
  transeType: string;
}

function Check(props: CheckProps): JSX.Element {
  const { trader, type, inquiryType, transeType } = props;
  const [checks, setChecks] = useState<Check[]>([]);
  const [checksByDate, setChecksByDate] = useState<{ [date: string]: Check[] }>(
    {}
  );
  const classes = useListStyles();

  const start_date =
    type === 'vat' ? trader.vat_start_date : trader.income_start_date;
  const end_date =
    type === 'vat' ? trader.vat_end_date : trader.income_end_date;

  useEffect(() => {
    const checksByDate: { [date: string]: Check[] } = {};
    for (const check of checks) {
      const list = checksByDate[check.issue_date] || [];
      list.push(check);
      checksByDate[check.issue_date] = list;
    }
    setChecksByDate(checksByDate);
  }, [checks]);

  useEffect(() => {
    axios
      .get(`${config.backendUri}/api/v2/checks/`, {
        params: {
          hometax__trader: trader.id,
          inquiry_type__in: inquiryType,
          transe_type: transeType,
          issue_date__gte: start_date,
          issue_date__lte: end_date,
        },
      })
      .then(({ data }) => {
        setChecks(data.results);
      });
  }, [trader.id, start_date, end_date, inquiryType, transeType]);

  return (
    <Container>
      <Box my={2}>
        <List>
          <Paper>
            {Object.keys(checksByDate).map((date) => (
              <li key={date}>
                <ul className={classes.noPadding}>
                  <ListSubheader className={classes.subheader}>
                    <div className={classes.row}>
                      <ListItemText
                        primary={new Date(date).toLocaleDateString()}
                      />
                      <ListItemText
                        className={classes.price}
                        primary={
                          checksByDate[date]
                            .reduce((acc, check) => acc + check.supply_value, 0)
                            .toLocaleString() + '원'
                        }
                        secondary={
                          checksByDate[date]
                            .reduce((acc, check) => acc + check.tax_amt, 0)
                            .toLocaleString() + '원'
                        }
                      />
                    </div>
                  </ListSubheader>
                  {checksByDate[date].map(
                    ({
                      id,
                      supplier_company_name,
                      contractor_company_name,
                      supply_value,
                      tax_amt,
                    }) => (
                      <ListItem key={id}>
                        <ListItemText
                          primary={
                            supplier_company_name || contractor_company_name
                          }
                          className={classes.caption}
                        />
                        <ListItemText
                          primary={supply_value.toLocaleString() + '원'}
                          secondary={tax_amt.toLocaleString() + '원'}
                          className={classes.price}
                        />
                      </ListItem>
                    )
                  )}
                </ul>
              </li>
            ))}
          </Paper>
        </List>
      </Box>
    </Container>
  );
}

export default Check;
