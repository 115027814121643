import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface ConfirmProps {
  title: string;
  description: ReactNode;
  ok: string;
  open: boolean;
  onOk: () => void;
  onClose: () => void;
}

export default function Confirm(props: ConfirmProps): JSX.Element {
  const { title, description, ok, open, onClose, onOk } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          취소
        </Button>
        <Button
          onClick={(): void => {
            onClose();
            onOk();
          }}
          color="primary"
          autoFocus
        >
          {ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
