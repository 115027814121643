import {
  AppBar,
  IconButton,
  Toolbar,
  Button,
  InputBase,
  Paper,
  makeStyles,
  Box,
  Hidden,
} from '@material-ui/core';
import React from 'react';
import {
  Switch,
  useRouteMatch,
  useHistory,
  Route,
  NavLink,
} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import ListBox from '../../components/ListBox';
import ChatRoom from './ChatRoom';
import Report from './Report';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  search: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    height: '100%',
  },
}));

function Manager(): JSX.Element {
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton edge="start" onClick={history.goBack}>
            <ArrowBackIcon color="inherit" />
          </IconButton>
          <Box display="flex" width="100%" justifyContent="space-between">
            <ListBox>
              <>
                <Button component={NavLink} to="/manager/chat">
                  채팅
                </Button>
                <Hidden smDown>
                  <Button href="/manager/messages">메세지</Button>
                  <Button href="/manager/traders">가입자</Button>
                  <Button href="/manager/boilerplates">상용구</Button>
                  <Button href="/manager/managers">매니저</Button>
                  <Button component={NavLink} to="/manager/report">
                    전자신고서
                  </Button>
                  <Button href="/manager/settings">설정</Button>
                </Hidden>
              </>
            </ListBox>
            <form action="/manager/traders" className={classes.searchContainer}>
              <Paper className={classes.search}>
                <InputBase name="search" />
                <IconButton type="submit" size="small">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </form>
          </Box>
        </Toolbar>
      </AppBar>

      <Switch>
        <Route path={`${match.url}/chat/:id?`} component={ChatRoom} />
        <Route path={`${match.url}/report`} component={Report} />
      </Switch>
    </>
  );
}

export default Manager;
