import React, { useCallback, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { DropzoneDialog } from 'material-ui-dropzone';
import axios from 'axios';
import { config } from '../../config';
import ReportTable from '../../components/Manager/Report/ReportTable';
import { Paginator, useStyle } from '../../components/common';
import { Report as ReportModel } from '../../types/Report';

export default function Report(): JSX.Element {
  const classes = useStyle();
  const [period, setPeriod] = useState<string>('');
  const [periods, setPeriods] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unsentReports, setUnsentReports] = useState({
    results: [],
  } as Paginator<ReportModel>);
  const [unsendableReports, setUnsendableReports] = useState({
    results: [],
  } as Paginator<ReportModel>);
  const [sentReports, setSentReports] = useState({
    results: [],
  } as Paginator<ReportModel>);

  const getUnsentReports = useCallback(async (): Promise<void> => {
    const { data } = await axios.get(`${config.backendUri}/api/v2/reports/`, {
      params: {
        has_co_invester: false,
        trader_count: 1,
        sent__isnull: true,
        period,
      },
    });

    setUnsentReports(data);
  }, [period]);

  const getUnsendableReports = useCallback(async (): Promise<void> => {
    const { data } = await axios.get(
      `${config.backendUri}/api/v2/reports/unsendable/`,
      {
        params: {
          period,
        },
      }
    );

    setUnsendableReports({
      results: data,
    });
  }, [period]);

  const getSentReports = useCallback(async (): Promise<void> => {
    const { data } = await axios.get(`${config.backendUri}/api/v2/reports/`, {
      params: {
        sent__isnull: false,
        period,
      },
    });

    setSentReports(data);
  }, [period]);

  useEffect(() => {
    if (!period) {
      return;
    }

    getUnsentReports().then();
    getUnsendableReports().then();
    getSentReports().then();
  }, [period, getSentReports, getUnsendableReports, getUnsentReports]);

  useEffect(() => {
    axios
      .get(`${config.backendUri}/api/v2/reports/periods/`)
      .then(({ data }) => {
        if (!data.length) {
          return;
        }

        setPeriods(data);
        setPeriod(data[0]);
      });
  }, []);

  const onSave = useCallback(
    async (files: File[]): Promise<void> => {
      setOpen(false);
      setLoading(true);

      const formData = new FormData();
      formData.append('file', files[0]);
      try {
        await axios.post(`${config.backendUri}/api/v2/reports/`, formData);
        await Promise.all([getUnsentReports(), getUnsendableReports()]);
      } finally {
        setLoading(false);
      }
    },
    [getUnsentReports, getUnsendableReports]
  );

  return (
    <Container>
      <Box my={2}>
        <Box my={2}>
          <FormControl style={{ minWidth: '100%' }}>
            <InputLabel id="period-label">과세기간</InputLabel>
            <Select
              id="period"
              labelId="period-label"
              onChange={(event): void => {
                setPeriod(event.target.value as string);
              }}
              value={period}
            >
              {periods.map((period) => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box my={2}>
          <ReportTable
            title="미전송 전자신고서"
            reports={unsentReports}
            onSend={async (): Promise<void> => {
              await Promise.all([getUnsentReports(), getSentReports()]);
            }}
            onDelete={getUnsentReports}
          />
        </Box>

        <Box my={2}>
          <ReportTable
            title="전송불가 전자신고서"
            reports={unsendableReports}
            onDelete={getUnsendableReports}
            selectable={false}
            fields={['note', 'confirmed', 'actions']}
          />
        </Box>

        <Box my={2}>
          <ReportTable
            title="전송한 전자신고서"
            reports={sentReports}
            onDelete={getSentReports}
            fields={['confirmed']}
          />
        </Box>
      </Box>

      <DropzoneDialog
        acceptedFiles={['text/plain']}
        filesLimit={1}
        maxFileSize={10000000}
        dropzoneText="파일을 끌어다 놓거나 클릭하세요."
        open={open}
        dialogTitle="전자신고 파일 업로드"
        cancelButtonText="취소"
        submitButtonText="업로드"
        onClose={(): void => setOpen(false)}
        onSave={onSave}
        showPreviews={false}
        showPreviewsInDropzone={true}
        showFileNames={true}
        previewGridProps={{
          container: { spacing: 0 },
        }}
      />

      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid className={classes.fab} container spacing={2}>
        <Grid item>
          <Fab
            color="secondary"
            href="https://docs.google.com/document/d/18gshrUJU2xE6q6eP5aPPbFwOZxvzlm665AVDskxG4RQ/"
            size="small"
            target="_blank"
          >
            <ContactSupportIcon />
          </Fab>
        </Grid>

        <Grid item>
          <Fab color="primary" onClick={(): void => setOpen(true)}>
            <CloudUploadIcon />
          </Fab>
        </Grid>
      </Grid>
    </Container>
  );
}
