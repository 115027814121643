import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AppBar, Box, Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ChatBubbleOutlineSharpIcon from '@material-ui/icons/ChatBubbleOutlineSharp';
import axios from 'axios';
import { config } from '../../../config';
import { Report } from '../../../types/Report';
import IncomeTax from './IncomeTax';
import GeneralValueAddedTax from './GeneralValueAddedTax';
import SimplifiedValueAddedTax from './SimplifiedValueAddedTax';
import CorporateTax from './CorporateTax';

const useStyle = makeStyles((theme) => ({
  box: {
    paddingTop: theme.spacing(2),
    paddingBottom: 60 + theme.spacing(2),
  },
  appBar: {
    backgroundColor: 'transparent',
    top: 'auto',
    bottom: 0,
  },
  confirm: {
    width: '100%',
    height: '60px',
  },
}));

interface ReportParams {
  signature: string;
}

export default function (): JSX.Element {
  const classes = useStyle();
  const { params } = useRouteMatch<ReportParams>();
  const [report, setReport] = useState<Report>({
    id: 0,
    name: '',
    has_co_invester: false,
    traders: [],
    trader_count: 0,
    parsed: {},
    confirmed: false,
  });
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    const { signature } = params;
    axios
      .get(`${config.backendUri}/api/v2/reports/signature?s=${signature}`)
      .then(({ data }) => {
        setReport(data);
        setConfirmed(data.confirmed);
      });
  }, [params]);

  const confirm = useCallback(() => {
    if (!confirmed) {
      const { signature } = params;
      axios
        .post(`${config.backendUri}/api/v2/reports/confirm/?s=${signature}`)
        .then(() => {
          setConfirmed(true);
        });
    }
  }, [params, confirmed]);

  return (
    <Fragment>
      <Container maxWidth="sm">
        <Box className={classes.box}>
          {report.parsed.일반과세자신고서 && (
            <GeneralValueAddedTax report={report} />
          )}
          {report.parsed.간이과세자신고서 && (
            <SimplifiedValueAddedTax report={report} />
          )}
          {report.parsed.사업소득명세서 && <IncomeTax report={report} />}
          {report.parsed.법인세과세표준및세액신고서 && (
            <CorporateTax report={report} />
          )}
        </Box>
      </Container>

      <AppBar position="fixed" className={classes.appBar}>
        {confirmed ? (
          <Button
            variant="contained"
            color="secondary"
            className={classes.confirm}
            startIcon={<ChatBubbleOutlineSharpIcon />}
            disabled
          >
            신고가 확정 되었습니다
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.confirm}
            onClick={confirm}
            startIcon={<CheckSharpIcon />}
          >
            신고확정
          </Button>
        )}
      </AppBar>
    </Fragment>
  );
}
