import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
  Card,
  CardContent,
  Tab,
  Tabs,
  CircularProgress,
  InputBaseComponentProps,
  Button,
} from '@material-ui/core';
import axios from 'axios';
import { useLocation, useHistory, Link, useRouteMatch } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { config } from '../../../config';
import Vat from './Vat';
import Income from './Income';

export function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return (): void => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function NumberFormatCustom(
  props: PropsWithChildren<InputBaseComponentProps>
): JSX.Element {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      defaultValue={''}
      getInputRef={inputRef}
      onValueChange={(values): void => {
        if (onChange) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          onChange({ target: { value: values.value } });
        }
      }}
      thousandSeparator
      suffix="원"
      style={{ textAlign: 'right' }}
    />
  );
}

export interface Vat {
  business_name: string;
  taxation: string;
  start_date: string;
  end_date: string;
  전자세금계산서매출: number;
  전자세금계산서매출세액: number;
  전자세금계산서매입: number;
  전자세금계산서매입세액: number;
  전자계산서매입: number;
  전자계산서매입세액: number;
  카드매출: number;
  카드매출세액: number;
  카드매입: number;
  카드매입세액: number;
  현금영수증매출: number;
  현금영수증매출세액: number;
  현금영수증매입: number;
  현금영수증매입세액: number;
  기타매출: number;
  기타매입: number;
}

export interface Income {
  business_name: string;
  taxation: string;
  start_date: string;
  end_date: string;
  전자세금계산서매출: number;
  전자세금계산서매입: number;
  전자계산서매출: number;
  전자계산서매입: number;
  카드매입: number;
  카드매출: number;
  인건비: number;
  현금영수증매출: number;
  현금영수증매입: number;
  기타매출: number;
  기타매입: number;
  기타수익: number;
}

interface TaxProps {
  traderId: number;
}

interface TaxState {
  value: number;
}

function Tax(props: TaxProps): JSX.Element {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation<TaxState>();
  const { traderId } = props;
  const [value, setValue] = useState(
    (location.state && location.state.value) || 0
  );
  const [scraping, setScraping] = useState(false);
  const [latest, setLatest] = useState<Date | null>(null);
  const [vat, setVat] = useState<Vat>();
  const [income, setIncome] = useState<Income>();

  const scrap = useCallback(() => {
    if (!traderId) {
      return;
    }

    setScraping(true);
    axios
      .post(`${config.backendUri}/api/v2/traders/${traderId}/scrap/`)
      .catch(() => {
        alert('정보 업데이트에 실패했습니다.');
      })
      .finally(() => setScraping(false));
  }, [traderId]);

  useEffect(() => {
    if (!traderId) {
      return;
    }

    axios
      .get(`${config.backendUri}/api/v2/traders/${traderId}/latest/`)
      .then(({ data }) => {
        if (data.latest) {
          const latest = new Date(data.latest);
          latest.setDate(latest.getDate() + 1);
          setLatest(latest);
        } else {
          setLatest(null);
        }
      });

    axios
      .get(`${config.backendUri}/api/v2/traders/${traderId}/vat/`)
      .then(({ data }) => {
        setVat(data);
      });

    axios
      .get(`${config.backendUri}/api/v2/traders/${traderId}/income/`)
      .then(({ data }) => {
        setIncome(data);
      });
  }, [traderId]);

  const handleChange = useCallback(
    (_, value) => {
      history.replace(location.pathname, {
        value,
      });
      setValue(value);
    },
    [history, location.pathname]
  );

  return (
    <Container>
      <Box py={2}>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                color="primary"
                onClick={scrap}
                disabled={
                  scraping ||
                  (!!latest &&
                    new Date().toLocaleDateString() ===
                      latest.toLocaleDateString())
                }
              >
                정보 업데이트
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<AddCircleIcon />}
                component={Link}
                to={`${match.url}/credentials`}
              >
                매입/매출 추가
              </Button>
            </Box>
            {latest && (
              <span>최종 업데이트: {latest.toLocaleDateString()}</span>
            )}

            <Tabs value={value} onChange={handleChange}>
              <Tab label="부가가치세" />
              <Tab label="종합소득세" />
            </Tabs>
            {((): ReactNode => {
              if (!vat || !income) {
                return <CircularProgress size="1.5rem" />;
              }

              if (value === 0) {
                return <Vat traderId={traderId} vat={vat} />;
              }

              if (value === 1) {
                return <Income traderId={traderId} income={income} />;
              }
            })()}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

export default Tax;
