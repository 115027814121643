import {
  createMuiTheme,
  responsiveFontSizes,
  Theme,
  ThemeOptions,
} from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import deepMerge from 'deepmerge';

const primary = '#2a2f4c';

const fontFamily = ['"Noto Sans KR"', 'sans-serif'].join(',');

function makeTheme(variant: ThemeOptions): Theme {
  const base: ThemeOptions = {
    overrides: {
      MuiButton: {
        contained: {
          backgroundColor: common.white,
        },
        text: {
          '&.active': {
            fontWeight: 900,
            color: primary,
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          body: {
            fontFamily,
          },
          html: {
            WebkitFontSmoothing: 'antialiased',
          },
        },
      },
    },
    palette: {
      primary: {
        main: primary,
      },
    },
  };
  const theme = createMuiTheme(deepMerge(base, variant));
  return responsiveFontSizes(theme);
}

const dark: ThemeOptions = {
  overrides: {
    MuiButton: {
      contained: {
        color: primary,
      },
      outlined: {
        color: common.white,
      },
      outlinedPrimary: {
        color: common.white,
      },
      outlinedSecondary: {
        color: common.white,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: primary,
      },
    },
  },
  palette: {
    type: 'dark',
  },
};

const light: ThemeOptions = {
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: common.white,
        color: primary,
      },
    },
  },
  palette: {
    type: 'light',
  },
  typography: {
    fontFamily,
  },
};

const themes = {
  dark: makeTheme(dark),
  light: makeTheme(light),
};

export default themes;
