import React, { ReactElement } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { config } from '../config';
import { useToken } from './TokenProvider';

export default function PrivateRoute(props: RouteProps): ReactElement {
  const [token] = useToken();
  if (!token.exp) {
    window.location.href = `${
      config.backendUri
    }/accounts/login/?next=${encodeURIComponent(window.location.href)}`;
    return <div />;
  }

  return <Route {...props} />;
}
