import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import axios from 'axios';
import { config } from '../../../config';
import { useListStyles } from '../../../styles/list';

interface CardApproval {
  id: number;
  used_date: string;
  used_amount: number;
  vat: number;
  member_store_name: string;
  type: string;
  checked: boolean;
}

interface CardApprovalProps {
  traderId: number;
  type: string;
}

function CardApproval(props: CardApprovalProps): JSX.Element {
  const { traderId } = props;
  const [approvals, setApprovals] = useState<CardApproval[]>([]);
  const [approvalsByDate, setApprovalsByDate] = useState<{
    [date: string]: CardApproval[];
  }>({});
  const classes = useListStyles();

  useEffect(() => {
    const approvalsByDate: {
      [data: string]: CardApproval[];
    } = {};
    for (const approval of approvals) {
      const key = new Date(approval.used_date).toLocaleDateString();
      const list = approvalsByDate[key] || [];
      list.push(approval);
      approvalsByDate[key] = list;
    }

    setApprovalsByDate(approvalsByDate);
  }, [approvals]);

  useEffect(() => {
    axios
      .get(`${config.backendUri}/api/v2/card-approvals/`, {
        params: {
          card__account__trader: traderId,
        },
      })
      .then(({ data }) => {
        setApprovals(data.results);
      });
  }, [traderId]);

  return (
    <Container>
      <Box my={2}>
        <List>
          <Paper>
            {Object.keys(approvalsByDate).map((date) => (
              <li key={date}>
                <ul className={classes.noPadding}>
                  <ListSubheader className={classes.subheader}>
                    <div className={classes.row}>
                      <ListItemText primary={date} />
                      <ListItemText
                        className={classes.price}
                        primary={
                          approvalsByDate[date]
                            .reduce(
                              (acc, approval) =>
                                acc + approval.used_amount - approval.vat,
                              0
                            )
                            .toLocaleString() + '원'
                        }
                        secondary={
                          approvalsByDate[date]
                            .reduce((acc, approval) => acc + approval.vat, 0)
                            .toLocaleString() + '원'
                        }
                      />
                    </div>
                  </ListSubheader>
                  {approvalsByDate[date].map(
                    ({
                      id,
                      used_date,
                      used_amount,
                      vat,
                      member_store_name,
                    }) => (
                      <ListItem key={id}>
                        <Box display="flex" flexDirection="column" width="100%">
                          <Box display="flex" alignItems="center" width="100%">
                            <ListItemText
                              primary={member_store_name}
                              secondary={new Date(
                                used_date
                              ).toLocaleTimeString()}
                              className={classes.caption}
                            />
                            <ListItemText
                              primary={
                                (used_amount - vat).toLocaleString() + '원'
                              }
                              secondary={vat.toLocaleString() + '원'}
                              className={classes.price}
                            />
                          </Box>
                        </Box>
                      </ListItem>
                    )
                  )}
                </ul>
              </li>
            ))}
          </Paper>
        </List>
      </Box>
    </Container>
  );
}

export default CardApproval;
