import React, { ChangeEvent } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useStyle } from './common';

interface EnhancedTableHeadProps {
  numSelected: number;
  onSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  selectable?: boolean;
  fields?: string[];
}

export default function EnhancedTableHead(
  props: EnhancedTableHeadProps
): JSX.Element {
  const { numSelected, rowCount, selectable, fields } = props;
  const classes = useStyle();

  return (
    <TableHead>
      <TableRow>
        {selectable !== false && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected !== 0 && numSelected !== rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={props.onSelectAll}
            />
          </TableCell>
        )}
        <TableCell className={classes.nameColumn}>대표자</TableCell>
        <TableCell>상호</TableCell>
        <TableCell>세액</TableCell>
        {fields && fields.indexOf('note') !== -1 && <TableCell>원인</TableCell>}
        {fields && fields.indexOf('confirmed') !== -1 && (
          <TableCell>신고확정</TableCell>
        )}
        {fields && fields.indexOf('actions') !== -1 && (
          <TableCell>액션</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
