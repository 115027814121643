import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
  },
}));

interface HometaxProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (
    loginType: string,
    username: string,
    password: string,
    callback: (successful: boolean) => void
  ) => void;
}

export default function Hometax(props: HometaxProps): JSX.Element {
  const { open, setOpen, onSubmit } = props;
  const classes = useStyles();
  const [loginType, setLoginType] = useState('1');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <Dialog fullScreen open={open}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="primary"
            onClick={(): void => {
              setLoginType('1');
              setUsername('');
              setPassword('');
              setOpen(false);
            }}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            className={classes.title}
            variant="h6"
            color="textPrimary"
          >
            홈택스
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        p={1}
      >
        <form
          onSubmit={(event): void => {
            event.preventDefault();
            setLoading(true);
            onSubmit(loginType, username, password, (successful: boolean) => {
              if (successful) {
                setLoginType('1');
                setUsername('');
                setPassword('');
                setOpen(false);
              }
              setLoading(false);
            });
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="아이디"
                value={username}
                onChange={(e): void => setUsername(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="패스워드"
                type="password"
                value={password}
                onChange={(e): void => setPassword(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size="1.5rem" /> : '추가'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
}
