import React, { ChangeEvent, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import axios from 'axios';
import { Report } from '../../../types/Report';
import { config } from '../../../config';
import { Paginator, useStyle } from '../../common';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import ReportTableRow from './ReportTableRow';

interface ReportTableProps {
  title: string;
  reports: Paginator<Report>;
  onSend?: () => Promise<void>;
  onDelete: () => Promise<void>;
  selectable?: boolean;
  fields?: string[];
}

export default function ReportTable(props: ReportTableProps): JSX.Element {
  const classes = useStyle();
  const { title, reports, onSend, onDelete, selectable, fields } = props;
  const [selected, setSelected] = useState(new Set<number>());
  const [loading, setLoading] = useState(false);

  const handleSend = async (): Promise<void> => {
    setLoading(true);
    try {
      await axios.post(`${config.backendUri}/api/v2/reports/send/`, {
        ids: Array.from(selected),
      });
      setSelected(new Set<number>());
      if (onSend) {
        await onSend();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (): Promise<void> => {
    setLoading(true);
    try {
      await axios.post(`${config.backendUri}/api/v2/reports/delete/`, {
        ids: Array.from(selected),
      });
      setSelected(new Set<number>());
      await onDelete();
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>): void => {
    const selected = new Set<number>();

    if (event.target.checked) {
      reports.results.forEach((r: Report) => selected.add(r.id));
    }

    setSelected(selected);
  };

  const handleSelect = (reportId: number): void => {
    if (selected.has(reportId)) {
      selected.delete(reportId);
    } else {
      selected.add(reportId);
    }

    setSelected(new Set(selected));
  };

  return (
    <Paper>
      <EnhancedTableToolbar
        title={title}
        numSelected={selected.size}
        onSend={handleSend}
        onDelete={handleDelete}
        selectable={selectable}
      />

      <TableContainer>
        <Table size="small">
          <EnhancedTableHead
            numSelected={selected.size}
            onSelectAll={handleSelectAll}
            rowCount={reports.results.length}
            selectable={selectable}
            fields={fields}
          />

          <TableBody>
            {reports.results.map((r: Report) => (
              <ReportTableRow
                key={r.id}
                report={r}
                onSelect={handleSelect}
                checked={selected.has(r.id)}
                selectable={selectable}
                fields={fields}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
}
