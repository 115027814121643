import React, { useCallback, useMemo } from 'react';
import {
  Grid,
  Hidden,
  ListItem,
  ListItemText,
  makeStyles,
  Box,
  Chip,
} from '@material-ui/core';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Trader } from '../../generated/graphql';
import Chats from './Chats';

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'auto',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 'calc(100vh - 48px)',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 64px)',
    },
  },
  name: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const GET_TRADERS = gql`
  {
    traders {
      id
      businessName
      unreadCount
    }
  }
`;

export default function ChatRoom(): JSX.Element {
  const { id } = useParams();
  const match = useRouteMatch();
  const history = useHistory();
  const { data } = useQuery(GET_TRADERS);
  const traders = useMemo(() => {
    if (!data || !data.traders) {
      return [];
    }

    return [...data.traders].sort((a: Trader, b: Trader) => {
      if (a.unreadCount && !b.unreadCount) {
        return -1;
      } else if (!a.unreadCount && b.unreadCount) {
        return 1;
      }

      return a.businessName > b.businessName ? 1 : -1;
    });
  }, [data]);
  const classes = useStyles();

  const handleClick = useCallback(
    (trader) => {
      const path = match.path.replace(':id', trader.id);
      if (id) {
        history.replace(path);
      } else {
        history.push(path);
      }
    },
    [history, id, match.path]
  );

  return (
    <Grid container>
      <Hidden xsDown={!!id}>
        <Grid
          item
          xs={12}
          sm={id ? 3 : 12}
          lg={id ? 2 : 12}
          className={classes.container}
        >
          {traders.map((trader: Trader) => (
            <ListItem
              key={trader.id}
              button
              selected={trader.id === id}
              onClick={(): void => handleClick(trader)}
              dense
            >
              <ListItemText>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <div className={classes.name}>{trader.businessName}</div>
                  {!!trader.unreadCount && (
                    <Chip
                      label={trader.unreadCount}
                      color="primary"
                      size="small"
                    />
                  )}
                </Box>
              </ListItemText>
            </ListItem>
          ))}
        </Grid>
      </Hidden>

      {id && (
        <Grid item xs={12} sm={9} lg={10} className={classes.container}>
          <Chats id={id} />
        </Grid>
      )}
    </Grid>
  );
}
