import { makeStyles } from '@material-ui/core/styles';

export const useListStyles = makeStyles((theme) => ({
  noPadding: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.grey.A100,
    border: 'solid 1px',
    borderStyle: 'solid none',
    borderColor: theme.palette.grey.A200,
    borderCollapse: 'collapse',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  caption: {
    '& > .MuiListItemText-primary': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  price: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
    minWidth: 'auto',
  },
}));
