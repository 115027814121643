import React, { useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Report } from '../../../types/Report';
import { ReactComponent as Logo } from '../logo-blue.svg';

const useStyle = makeStyles(() => ({
  amount: {
    boxShadow: 'inset 0 -10px 0 #b4e7f8',
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  footer: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: theme.palette.common.black,
  },
}))(TableCell);

function 사업구분(사업구분코드: string): string {
  switch (사업구분코드) {
    case '51':
      return '근로';
    case '52':
      return '근로';
    case '53':
      return '근로';
    case '55':
      return '근로';
    case '56':
      return '근로';
    case '57':
      return '근로';
    case '60':
      return '기타';
    case '61':
      return '기타';
    case '66':
      return '연금';
    case '67':
      return '종교인';
    default:
      return '';
  }
}

interface IncomeTaxProps {
  report: Report;
}

function IncomeTax(props: IncomeTaxProps): JSX.Element {
  const classes = useStyle();
  const { report } = props;
  const [사업장매출Open, set사업장매출Open] = useState(true);
  const [사업장비용Open, set사업장비용Open] = useState(true);
  const [사업장소득Open, set사업장소득Open] = useState(true);
  const [타수입금액Open, set타수입금액Open] = useState(false);

  const 사업장매출 = useMemo(() => {
    return report.parsed
      .사업소득명세서!.filter((r) => parseInt(r.사업자등록번호) >= 30)
      .reduce((acc: number, cur) => acc + parseInt(cur.총수입금액), 0);
  }, [report]);

  const 사업장비용 = useMemo(() => {
    return report.parsed
      .사업소득명세서!.filter((r) => parseInt(r.사업자등록번호) >= 30)
      .reduce((acc: number, cur) => acc + parseInt(cur.필요경비), 0);
  }, [report]);

  const 사업장소득 = useMemo(() => {
    return report.parsed
      .사업소득명세서!.filter((r) => parseInt(r.사업자등록번호) >= 30)
      .reduce((acc: number, cur) => acc + parseInt(cur.소득금액), 0);
  }, [report]);

  const 타수입금액 = useMemo(() => {
    let 타수입금액 = report.parsed
      .사업소득명세서!.filter((r) => parseInt(r.사업자등록번호) < 30)
      .reduce((acc: number, cur) => acc + parseInt(cur.총수입금액), 0);

    타수입금액 += report.parsed.근로연금기타소득명세서!.reduce(
      (acc: number, cur) => acc + parseInt(cur.총수입금액_총급여액),
      0
    );
    return 타수입금액;
  }, [report]);

  const 종합소득금액 = useMemo(() => {
    return parseInt(report.parsed.세액의계산!.종합소득금액 || '0');
  }, [report]);

  const 소득공제액 = useMemo(() => {
    return parseInt(report.parsed.세액의계산!.소득공제 || '0');
  }, [report]);

  const 세액감면액 = useMemo(() => {
    return parseInt(report.parsed.세액의계산!.종합소득세_세액감면 || '0');
  }, [report]);

  const 세액공제액 = useMemo(() => {
    return parseInt(report.parsed.세액의계산!.종합소득세_세액공제 || '0');
  }, [report]);

  const 기납부세액 = useMemo(() => {
    return parseInt(report.parsed.기납부세액명세서!.기납부세액합계_소득 || '0');
  }, [report]);

  const 납부할세액 = useMemo(() => {
    return parseInt(report.parsed.세액의계산!.종합소득세_납부할총세액 || '0');
  }, [report]);

  const period = useMemo(() => {
    if (!report.period_begin) {
      return '';
    }

    return `${report.period_begin} ~ ${report.period_end}`;
  }, [report]);

  return (
    <Card>
      <CardHeader
        avatar={<Logo />}
        title="종합소득세 결과 안내문"
        subheader={period}
      />

      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {report.name} 대표님께서 내실 금액은{' '}
          <span className={classes.amount}>
            {납부할세액.toLocaleString()}원
          </span>
          입니다.
        </Typography>
        <Typography variant="body1">지방소득세는 별도로 발생합니다.</Typography>
        <Typography variant="body2" color="textSecondary">
          신고금액을 확인하신 후 신고확정 버튼을 눌러주세요.
        </Typography>

        <Table>
          <TableBody>
            <TableRow
              className={classes.row}
              hover
              onClick={(): void => set사업장매출Open(!사업장매출Open)}
            >
              <StyledTableCell>
                사업장매출
                {
                  <IconButton size="small">
                    {사업장매출Open ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                }
              </StyledTableCell>
              <StyledTableCell align="right">
                {사업장매출.toLocaleString()}원
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 0,
                }}
                colSpan={2}
              >
                <Collapse in={사업장매출Open} timeout="auto" unmountOnExit>
                  <Table size="small">
                    <TableBody>
                      {report.parsed.총수입금액조정명세서 &&
                        report.parsed.총수입금액조정명세서
                          .filter((r) => parseInt(r.사업자등록번호) >= 30)
                          .map((r, index) => {
                            return (
                              <TableRow
                                key={`사업장매출-${index}`}
                                className={classes.row}
                              >
                                <StyledTableCell>
                                  {
                                    report.parsed.사업소득명세서!.find(
                                      (사업소득명세서) =>
                                        r.사업자등록번호 ===
                                        사업소득명세서.사업자등록번호
                                    )?.상호
                                  }
                                  <Chip size="small" label={r.계정과목} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {parseInt(r.조정수입금액).toLocaleString()}원
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                      {!report.parsed.총수입금액조정명세서 &&
                        report.parsed
                          .사업소득명세서!.filter(
                            (r) => parseInt(r.사업자등록번호) >= 30
                          )
                          .map((r) => {
                            return (
                              <TableRow
                                key={`사업장매출-${r.id}`}
                                className={classes.row}
                              >
                                <StyledTableCell>
                                  {r.상호}
                                  <Chip size="small" label="사업" />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {parseInt(r.총수입금액).toLocaleString()}원
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>

            <TableRow
              className={classes.row}
              hover
              onClick={(): void => set사업장비용Open(!사업장비용Open)}
            >
              <StyledTableCell>
                사업장비용
                {
                  <IconButton size="small">
                    {사업장비용Open ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                }
              </StyledTableCell>
              <StyledTableCell align="right">
                {사업장비용.toLocaleString()}원
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 0,
                }}
                colSpan={2}
              >
                <Collapse in={사업장비용Open} timeout="auto" unmountOnExit>
                  <Table size="small">
                    <TableBody>
                      {report.parsed
                        .사업소득명세서!.filter(
                          (r) => parseInt(r.사업자등록번호) >= 30
                        )
                        .map((r) => {
                          return (
                            <TableRow
                              key={`사업장비용-${r.id}`}
                              className={classes.row}
                            >
                              <StyledTableCell>
                                {r.상호}
                                <Chip size="small" label="사업" />
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {parseInt(r.필요경비).toLocaleString()}원
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>

            <TableRow
              className={classes.row}
              hover
              onClick={(): void => set사업장소득Open(!사업장소득Open)}
            >
              <StyledTableCell>
                사업장소득
                {
                  <IconButton size="small">
                    {사업장소득Open ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                }
              </StyledTableCell>
              <StyledTableCell align="right">
                {사업장소득.toLocaleString()}원
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 0,
                }}
                colSpan={2}
              >
                <Collapse in={사업장소득Open} timeout="auto" unmountOnExit>
                  <Table size="small">
                    <TableBody>
                      {report.parsed
                        .사업소득명세서!.filter(
                          (r) => parseInt(r.사업자등록번호) >= 30
                        )
                        .map((r) => {
                          return (
                            <TableRow
                              key={`사업장소득-${r.id}`}
                              className={classes.row}
                            >
                              <StyledTableCell>
                                {r.상호}
                                <Chip size="small" label="사업" />
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {parseInt(r.소득금액).toLocaleString()}원
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>

            <TableRow
              className={classes.row}
              hover
              onClick={(): void => set타수입금액Open(!타수입금액Open)}
            >
              <StyledTableCell>
                타수입금액
                {
                  <IconButton size="small">
                    {타수입금액Open ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                }
              </StyledTableCell>
              <StyledTableCell align="right">
                {타수입금액.toLocaleString()}원
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 0,
                }}
                colSpan={2}
              >
                <Collapse in={타수입금액Open} timeout="auto" unmountOnExit>
                  <Table size="small">
                    <TableBody>
                      {report.parsed
                        .사업소득명세서!.filter(
                          (r) => parseInt(r.사업자등록번호) < 30
                        )
                        .map((r) => {
                          return (
                            <TableRow
                              key={`타수입금액-근로-${r.id}`}
                              className={classes.row}
                            >
                              <StyledTableCell>
                                {r.상호}
                                <Chip size="small" label="사업" />
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {parseInt(r.총수입금액).toLocaleString()}원
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}

                      {report.parsed.근로연금기타소득명세서!.map((r) => {
                        return (
                          <TableRow
                            key={`타수입금액-기타-${r.id}`}
                            className={classes.row}
                          >
                            <StyledTableCell>
                              {r.소득지급자_상호}
                              <Chip
                                size="small"
                                label={사업구분(r.수득구분코드)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {parseInt(r.총수입금액_총급여액).toLocaleString()}
                              원
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell>종합소득금액</StyledTableCell>
              <StyledTableCell align="right">
                {종합소득금액.toLocaleString()}원
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell>소득공제액</StyledTableCell>
              <StyledTableCell align="right">
                {소득공제액.toLocaleString()}원
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell>세액감면액</StyledTableCell>
              <StyledTableCell align="right">
                {세액감면액.toLocaleString()}원
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell>세액공제액</StyledTableCell>
              <StyledTableCell align="right">
                {세액공제액.toLocaleString()}원
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell>기납부세액</StyledTableCell>
              <StyledTableCell align="right">
                {기납부세액.toLocaleString()}원
              </StyledTableCell>
            </TableRow>
          </TableBody>

          <TableFooter>
            <TableRow>
              <StyledTableCell>종합소득세</StyledTableCell>
              <StyledTableCell align="right">
                {납부할세액.toLocaleString()}원
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell>지방소득세</StyledTableCell>
              <StyledTableCell align="right">추가 안내</StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
}

export default IncomeTax;
