import React, {
  Fragment,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useRouteMatch, useHistory, Route, Switch } from 'react-router-dom';
import {
  AppBar,
  Box,
  CircularProgress,
  IconButton,
  List,
  Toolbar,
  ListItem,
  ListItemText,
  Divider,
  Drawer,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChatIcon from '@material-ui/icons/Chat';
import MenuIcon from '@material-ui/icons/Menu';
import axios from 'axios';
import { config } from '../../../config';
import { Trader } from '../../../types/Report';
import Credential from './Credential';
import Tax from './Tax';
import CardApproval from './CardApproval';
import Check from './Check';
import CardSalesApproval from './CardSalesApproval';
import WithholdingTax from './WithholdingTax';
import CashSalesReceipt from './CashSalesReceipt';
import CashPurchaseReceipt from './CashPurchaseReceipt';

const useStyles = makeStyles(() => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  list: {
    width: 250,
  },
}));

interface HomeParams {
  traderId?: string;
}

function Home(): JSX.Element {
  const history = useHistory();
  const match = useRouteMatch<HomeParams>();
  const traderId = parseInt(match.params.traderId || '0');
  const [loading, setLoading] = useState(true);
  const [trader, setTrader] = useState<Trader | null>();
  const [traders, setTraders] = useState<Trader[]>([]);
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();

  const updateTrader = useCallback(
    (trader) => {
      if (!trader) {
        return;
      }

      setTrader(trader);
      if (trader.id !== parseInt(match.params.traderId || '0')) {
        history.replace(`/${trader.id}`);
      }
    },
    [history, match]
  );

  useEffect(() => {
    axios.get(`${config.backendUri}/api/v2/traders/mine/`).then(({ data }) => {
      if (data && !data.length) {
        window.location.href = `${config.backendUri}/traders/new/`;
      }

      const trader = data.find((t: Trader) => t.id === traderId) || data[0];
      setLoading(false);
      setTraders(data);
      updateTrader(trader);
    });
  }, [traderId, updateTrader]);

  const toggleDrawer = useCallback(
    (open) => (event: MouseEvent | KeyboardEvent): void => {
      if (event.type === 'keydown' && event.metaKey) {
        return;
      }

      setDrawer(open);
    },
    []
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Drawer anchor="left" open={drawer} onClose={toggleDrawer(false)}>
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {traders.map((t) => (
              <ListItem
                button
                key={t.business_name}
                onClick={(): void =>
                  updateTrader(traders.find(({ id }) => id === t.id))
                }
              >
                <ListItemText primary={t.business_name} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            <ListItem
              button
              component="a"
              href={`${config.backendUri}/accounts`}
            >
              <ListItemText primary="설정" />
            </ListItem>
          </List>
        </div>
      </Drawer>

      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>

          {trader && (
            <Fragment>
              <Typography color="textPrimary">
                {trader.business_name}
              </Typography>

              <a href={`${config.backendUri}/messages`}>
                <IconButton color="primary">
                  <ChatIcon />
                </IconButton>
              </a>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>

      {trader && (
        <Switch>
          <Route path={`${match.url}/credentials/hometax`}>
            <Credential traderId={traderId} />
          </Route>
          <Route path={`${match.url}/credentials/card`}>
            <Credential traderId={traderId} />
          </Route>
          <Route path={`${match.url}/credentials/card-sales`}>
            <Credential traderId={traderId} />
          </Route>
          <Route path={`${match.url}/credentials`}>
            <Credential traderId={traderId} />
          </Route>

          <Route path={`${match.url}/vat-card-approvals`}>
            <CardApproval traderId={traderId} type="vat" />
          </Route>
          <Route path={`${match.url}/vat-card-sales-approvals`}>
            <CardSalesApproval trader={trader} type="vat" />
          </Route>
          <Route path={`${match.url}/vat-sales-tax-checks`}>
            <Check
              trader={trader}
              type="vat"
              inquiryType="01,02"
              transeType="01"
            />
          </Route>
          <Route path={`${match.url}/vat-purchase-tax-checks`}>
            <Check
              trader={trader}
              type="vat"
              inquiryType="01,02"
              transeType="02"
            />
          </Route>
          <Route path={`${match.url}/vat-sales-checks`}>
            <Check
              trader={trader}
              type="vat"
              inquiryType="03,04"
              transeType="01"
            />
          </Route>
          <Route path={`${match.url}/vat-purchase-checks`}>
            <Check
              trader={trader}
              type="vat"
              inquiryType="03,04"
              transeType="02"
            />
          </Route>
          <Route path={`${match.url}/vat-cash-sales-receipts`}>
            <CashSalesReceipt trader={trader} type="vat" />
          </Route>
          <Route path={`${match.url}/vat-cash-purchase-receipts`}>
            <CashPurchaseReceipt trader={trader} type="vat" />
          </Route>
          <Route path={`${match.url}/vat-withholding-taxes`}>
            <WithholdingTax trader={trader} type="vat" />
          </Route>

          <Route path={`${match.url}/income-card-approvals`}>
            <CardApproval traderId={traderId} type="income" />
          </Route>
          <Route path={`${match.url}/income-card-sales-approvals`}>
            <CardSalesApproval trader={trader} type="income" />
          </Route>
          <Route path={`${match.url}/income-sales-tax-checks`}>
            <Check
              trader={trader}
              type="income"
              inquiryType="01,02"
              transeType="01"
            />
          </Route>
          <Route path={`${match.url}/income-purchase-tax-checks`}>
            <Check
              trader={trader}
              type="income"
              inquiryType="01,02"
              transeType="02"
            />
          </Route>
          <Route path={`${match.url}/income-sales-checks`}>
            <Check
              trader={trader}
              type="income"
              inquiryType="03,04"
              transeType="01"
            />
          </Route>
          <Route path={`${match.url}/income-purchase-checks`}>
            <Check
              trader={trader}
              type="income"
              inquiryType="03,04"
              transeType="02"
            />
          </Route>
          <Route path={`${match.url}/income-cash-sales-receipts`}>
            <CashSalesReceipt trader={trader} type="income" />
          </Route>
          <Route path={`${match.url}/income-cash-purchase-receipts`}>
            <CashPurchaseReceipt trader={trader} type="income" />
          </Route>
          <Route path={`${match.url}/income-withholding-taxes`}>
            <WithholdingTax trader={trader} type="income" />
          </Route>

          <Route exact path={`${match.url}`}>
            <Tax traderId={traderId} />
          </Route>
        </Switch>
      )}

      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default Home;
