import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MessageNode } from '../../generated/graphql';

const useStyles = makeStyles((theme) => ({
  name: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  chatContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
  },
  bubble: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    overflowWrap: 'anywhere',
  },
  date: {
    whiteSpace: 'nowrap',
  },
}));

interface ChatProps {
  chat: MessageNode;
  me?: boolean;
  continued?: boolean;
}

export default function Chat(props: ChatProps): JSX.Element {
  const { chat, me, continued } = props;
  const classes = useStyles();

  return (
    <Box px={1}>
      <Box className={classes.name} flexDirection={me ? 'row-reverse' : 'row'}>
        {!continued && chat.sender.name}
      </Box>
      <Box
        className={classes.chatContainer}
        flexDirection={me ? 'row-reverse' : 'row'}
      >
        <Box
          className={classes.bubble}
          dangerouslySetInnerHTML={{ __html: chat.content }}
        />
        <Typography variant="caption" className={classes.date}>
          {new Date(chat.created).toLocaleTimeString()}
        </Typography>
      </Box>
    </Box>
  );
}
