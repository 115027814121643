import React, { Fragment, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Report } from '../../../types/Report';
import { ReactComponent as Logo } from '../logo-blue.svg';

const useStyle = makeStyles(() => ({
  amount: {
    boxShadow: 'inset 0 -10px 0 #b4e7f8',
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

interface GeneralValueAddedTaxProps {
  report: Report;
}

export default function GeneralValueAddedTax(
  props: GeneralValueAddedTaxProps
): JSX.Element {
  const { report } = props;
  const {
    차감납부할세액,
    환급세액,
    경감공제합계세액,
    예정신고미환급세액,
    예정고지세액,
    소규모개인사업자부가가치세감면세액,
    가산세액계,
    과세표준금액,
    매출과세세금계산서발급금액,
    매출과세매입자발행세금계산서금액,
    매출과세카드현금발행금액,
    매출과세기타금액,
    산출세액,
    차감합계금액,
    매입세금계산서수취일반금액,
    매입세금계산서수취고정자산금액,
    매입기타공제매입금액,
    공제받지못할매입합계금액,
    차감합계세액,
  } = report.parsed.일반과세자신고서 || {};
  const classes = useStyle();
  const tableRef = useRef<HTMLTableElement>(null);
  const [매출Open, set매출Open] = useState(false);
  const [매입Open, set매입Open] = useState(false);

  const period = useMemo(() => {
    if (!report.period_begin) {
      return '';
    }

    return `${report.period_begin} ~ ${report.period_end}`;
  }, [report]);

  return (
    <Fragment>
      <Box mb={1}>
        <Card>
          <CardHeader
            avatar={<Logo />}
            title="부가가치세 결과 안내문"
            subheader={period}
          />

          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
              {report.name} 대표님께서 내실 금액은{' '}
              <span className={classes.amount}>
                {parseInt(차감납부할세액 || '0').toLocaleString()}원
              </span>
              입니다.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              신고금액을 확인하신 후 신고확정 버튼을 눌러주세요.
            </Typography>

            <Table>
              <TableBody>
                <TableRow
                  hover
                  onClick={(): void =>
                    window.scrollTo({
                      top: (tableRef.current || {}).offsetTop,
                      behavior: 'smooth',
                    })
                  }
                >
                  <TableCell>
                    <Badge badgeContent="?" color="primary">
                      <small>매출세액-매입세액&nbsp;&nbsp;</small>
                    </Badge>
                  </TableCell>
                  <TableCell align="right">
                    {parseInt(환급세액 || '0').toLocaleString()}원
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Box display="flex">
                      <RemoveCircleOutlineIcon fontSize="small" />
                      공제액
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {parseInt(경감공제합계세액 || '0').toLocaleString()}원
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <RemoveCircleOutlineIcon fontSize="small" />
                      <small>
                        소규모개인사업자
                        <br />
                        감면
                      </small>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {parseInt(
                      소규모개인사업자부가가치세감면세액 || '0'
                    ).toLocaleString()}
                    원
                  </TableCell>
                </TableRow>

                {parseInt(예정신고미환급세액 || '0') !== 0 && (
                  <TableRow>
                    <TableCell>
                      <Box display="flex">
                        <RemoveCircleOutlineIcon fontSize="small" />
                        예정신고미환급세액
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {parseInt(예정신고미환급세액 || '0').toLocaleString()}원
                    </TableCell>
                  </TableRow>
                )}

                {parseInt(예정고지세액 || '0') !== 0 && (
                  <TableRow>
                    <TableCell>
                      <Box display="flex">
                        <RemoveCircleOutlineIcon fontSize="small" />
                        예정고지세액
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {parseInt(예정고지세액 || '0').toLocaleString()}원
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell>
                    <Box display="flex">
                      <AddCircleOutlineIcon fontSize="small" />
                      가산세액
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {parseInt(가산세액계 || '0').toLocaleString()}원
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>부가가치세</TableCell>
                  <TableCell align="right">
                    <strong>
                      {parseInt(차감납부할세액 || '0').toLocaleString()}원
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>

      <Card>
        <CardContent>
          <Typography variant="subtitle1">매출세액-매입세액</Typography>

          <Table ref={tableRef}>
            <TableBody>
              <TableRow
                className={classes.row}
                hover
                onClick={(): void => set매출Open(!매출Open)}
              >
                <TableCell>
                  매출
                  {
                    <IconButton size="small">
                      {매출Open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  }
                </TableCell>
                <TableCell align="right">
                  {parseInt(과세표준금액 || '0').toLocaleString()}원
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingRight: 0,
                  }}
                  colSpan={2}
                >
                  <Collapse in={매출Open} timeout="auto" unmountOnExit>
                    <Table size="small">
                      <TableBody>
                        <TableRow className={classes.row}>
                          <TableCell>전자계산서</TableCell>
                          <TableCell align="right">
                            {parseInt(
                              매출과세세금계산서발급금액 || '0'
                            ).toLocaleString()}
                            원
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                          <TableCell>
                            <small>
                              매입자발행
                              <br />
                              세금계산서
                            </small>
                          </TableCell>
                          <TableCell align="right">
                            {parseInt(
                              매출과세매입자발행세금계산서금액 || '0'
                            ).toLocaleString()}
                            원
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                          <TableCell>
                            <small>
                              신용카드 및
                              <br />
                              현금영수증
                            </small>
                          </TableCell>
                          <TableCell align="right">
                            {parseInt(
                              매출과세카드현금발행금액 || '0'
                            ).toLocaleString()}
                            원
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                          <TableCell>기타</TableCell>
                          <TableCell align="right">
                            {parseInt(매출과세기타금액 || '0').toLocaleString()}
                            원
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>매출세액</TableCell>
                <TableCell align="right">
                  {parseInt(산출세액 || '0').toLocaleString()}원
                </TableCell>
              </TableRow>

              <TableRow
                className={classes.row}
                hover
                onClick={(): void => set매입Open(!매입Open)}
              >
                <TableCell>
                  매입
                  {
                    <IconButton size="small">
                      {매입Open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  }
                </TableCell>
                <TableCell align="right">
                  {parseInt(차감합계금액 || '0').toLocaleString()}원
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingRight: 0,
                  }}
                  colSpan={2}
                >
                  <Collapse in={매입Open} timeout="auto" unmountOnExit>
                    <Table size="small">
                      <TableBody>
                        <TableRow className={classes.row}>
                          <TableCell>전자계산서</TableCell>
                          <TableCell align="right">
                            {parseInt(
                              매입세금계산서수취일반금액 || '0'
                            ).toLocaleString()}
                            원
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                          <TableCell>
                            <small>
                              고정자산매입
                              <br />
                              세금계산서
                            </small>
                          </TableCell>
                          <TableCell align="right">
                            {parseInt(
                              매입세금계산서수취고정자산금액 || '0'
                            ).toLocaleString()}
                            원
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                          <TableCell>기타</TableCell>
                          <TableCell align="right">
                            {parseInt(
                              매입기타공제매입금액 || '0'
                            ).toLocaleString()}
                            원
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <RemoveCircleOutlineIcon fontSize="small" />
                              <small>
                                공제받지 못할
                                <br />
                                매입금액
                              </small>
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            {parseInt(
                              공제받지못할매입합계금액 || '0'
                            ).toLocaleString()}
                            원
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>매입세액</TableCell>
                <TableCell align="right">
                  {parseInt(차감합계세액 || '0').toLocaleString()}원
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Fragment>
  );
}
