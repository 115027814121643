import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { config } from '../../../config';
import ListBox from '../../../components/ListBox';
import { Income as IncomeModel, NumberFormatCustom, useDebounce } from './Tax';

const useStyle = makeStyles(() => ({
  amount: {
    boxShadow: 'inset 0 -10px 0 #b4e7f8',
  },
  noPadding: {
    padding: 0,
  },
}));

interface IncomeProps {
  traderId: number;
  income: IncomeModel;
}

function Income(props: IncomeProps): JSX.Element {
  const match = useRouteMatch();
  const { traderId, income } = props;
  const classes = useStyle();
  const [기타매출, set기타매출] = useState(
    income.기타매출 ? income.기타매출.toString() : ''
  );
  const [기타매입, set기타매입] = useState(
    income.기타매입 ? income.기타매입.toString() : ''
  );
  const [기타수익, set기타수익] = useState(
    income.기타수익 ? income.기타수익.toString() : ''
  );
  const debounced기타매출 = useDebounce(기타매출, 500);
  const debounced기타매입 = useDebounce(기타매입, 500);
  const debounced기타수익 = useDebounce(기타수익, 500);

  useEffect(() => {
    const 기타매출 = parseInt(debounced기타매출 || '0');
    if (기타매출 === income.기타매출) {
      return;
    }

    axios.patch(`${config.backendUri}/api/v2/traders/${traderId}/`, {
      other_income_sales: 기타매출,
    });
  }, [traderId, income, debounced기타매출]);

  useEffect(() => {
    const 기타매입 = parseInt(debounced기타매입 || '0');
    if (기타매입 === income.기타매입) {
      return;
    }

    axios.patch(`${config.backendUri}/api/v2/traders/${traderId}/`, {
      other_income_purchase: 기타매입,
    });
  }, [traderId, income, debounced기타매입]);

  useEffect(() => {
    const 기타수익 = parseInt(debounced기타수익 || '0');
    if (기타수익 === income.기타수익) {
      return;
    }

    axios.patch(`${config.backendUri}/api/v2/traders/${traderId}/`, {
      other_income: 기타수익,
    });
  }, [traderId, income, debounced기타수익]);

  const 구간 = [
    12000000,
    34000000,
    42000000,
    62000000,
    150000000,
    200000000,
    0,
  ];
  const 구간별세율 = [0.06, 0.15, 0.24, 0.35, 0.38, 0.4, 0.42];

  const 매출 = useMemo(
    (): number =>
      income.전자세금계산서매출 +
      income.전자계산서매출 +
      income.카드매출 +
      income.현금영수증매출 +
      parseInt(기타매출 || '0') +
      parseInt(기타수익 || '0'),
    [income, 기타매출, 기타수익]
  );
  const 비용 = useMemo(
    (): number =>
      income.전자세금계산서매입 +
      income.전자계산서매입 +
      income.카드매입 +
      income.현금영수증매입 +
      income.인건비 +
      parseInt(기타매입 || '0'),
    [income, 기타매입]
  );
  const 종합소득금액 = useMemo((): number => 매출 - 비용, [매출, 비용]);
  const 기본공제 = useMemo((): number => {
    if (종합소득금액 <= 0) {
      return 0;
    }

    if (종합소득금액 < 1500000) {
      return -종합소득금액;
    }

    return -1500000;
  }, [종합소득금액]);
  const 과세표준 = useMemo(() => {
    return Math.max(종합소득금액 + 기본공제, 0);
  }, [종합소득금액, 기본공제]);
  const 산출세액 = useMemo(() => {
    let 잔액 = 과세표준;
    let 산출세액 = 0;
    for (const i in 구간) {
      const 과세표준 = Math.min(잔액, 구간[i]);
      산출세액 += 과세표준 * 구간별세율[i];

      잔액 -= 과세표준;
      if (잔액 <= 0) {
        break;
      }
    }

    if (잔액 > 0) {
      산출세액 += 잔액 * 구간별세율[구간별세율.length - 1];
    }

    return Math.floor(산출세액);
  }, [구간, 구간별세율, 과세표준]);
  const 표준세액공제 = useMemo((): number => {
    if (산출세액 <= 0) {
      return 0;
    }

    if (산출세액 < 70000) {
      return -산출세액;
    }

    return 70000;
  }, [산출세액]);
  const 결정세액 = useMemo(() => {
    return Math.max(산출세액 + 표준세액공제, 0);
  }, [산출세액, 표준세액공제]);
  const 세율 = useMemo((): number => {
    let 잔액 = 과세표준;
    for (const i in 구간) {
      잔액 -= 구간[i];
      if (잔액 <= 0) {
        return 구간별세율[i];
      }
    }

    return 구간별세율[구간별세율.length - 1];
  }, [구간, 구간별세율, 과세표준]);

  return (
    <Fragment>
      <Typography gutterBottom variant="h6" component="h2">
        {income.business_name}의 예상 종합소득세는{' '}
        <span className={classes.amount}>{결정세액.toLocaleString()}원</span>
        입니다.
        <Alert severity="success">
          예상세액은 실제 납부할 세액과 다를 수 있습니다. 기타 매출/매입/소득을
          입력하여 더 정확한 예상 세금을 얻을 수 있습니다.
        </Alert>
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>매출</Typography>
            <Typography>{매출.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/income-sales-tax-checks`}
              >
                <TableCell>전자세금계산서</TableCell>
                <TableCell align="right">
                  {income.전자세금계산서매출.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/income-sales-checks`}
              >
                <TableCell>전자계산서</TableCell>
                <TableCell align="right">
                  {income.전자계산서매출.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/income-card-sales-approvals`}
              >
                <TableCell>카드단말기</TableCell>
                <TableCell align="right">
                  {income.카드매출.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/income-cash-sales-receipts`}
              >
                <TableCell>현금영수증</TableCell>
                <TableCell align="right">
                  {income.현금영수증매출.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>기타 매출</TableCell>
                <TableCell align="right">
                  <TextField
                    value={기타매출}
                    onChange={(event): void => {
                      const floatValue = parseInt(event.target.value);
                      if (floatValue) {
                        set기타매출(floatValue.toString());
                      } else {
                        set기타매출('');
                      }
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>기타 수익</TableCell>
                <TableCell align="right">
                  <TextField
                    value={기타수익}
                    onChange={(event): void => {
                      const floatValue = parseInt(event.target.value);
                      if (floatValue) {
                        set기타수익(floatValue.toString());
                      } else {
                        set기타수익('');
                      }
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <ListBox>
                    <Fragment>
                      <Box>
                        <Chip
                          label={`${income.start_date}~${income.end_date}`}
                          size="small"
                          color="primary"
                          icon={<DateRangeIcon />}
                        />
                      </Box>
                      <ListBox>
                        <Fragment>
                          <Chip
                            label="기타 매출"
                            variant="outlined"
                            color="primary"
                            size="small"
                          />
                          종이 세금계산서, 종이 계산서, 온라인, 현금 매출의 합계
                        </Fragment>
                      </ListBox>
                      <ListBox>
                        <Fragment>
                          <Chip
                            label="기타 수익"
                            variant="outlined"
                            color="primary"
                            size="small"
                          />
                          사업장 외 소득, 보조금 수익
                        </Fragment>
                      </ListBox>
                    </Fragment>
                  </ListBox>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>비용</Typography>
            <Typography>{비용.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/income-purchase-tax-checks`}
              >
                <TableCell>전자세금계산서</TableCell>
                <TableCell align="right">
                  {income.전자세금계산서매입.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/income-purchase-checks`}
              >
                <TableCell>전자계산서</TableCell>
                <TableCell align="right">
                  {income.전자계산서매입.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/income-card-approvals`}
              >
                <TableCell>사업용 신용카드</TableCell>
                <TableCell align="right">
                  {income.카드매입.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/income-cash-purchase-receipts`}
              >
                <TableCell>현금영수증</TableCell>
                <TableCell align="right">
                  {income.현금영수증매입.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow
                hover
                component={Link}
                to={`${match.url}/income-withholding-taxes`}
              >
                <TableCell>인건비</TableCell>
                <TableCell align="right">
                  {income.인건비.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>기타 매입</TableCell>
                <TableCell align="right">
                  <TextField
                    value={기타매입}
                    onChange={(event): void => {
                      const floatValue = parseInt(event.target.value);
                      if (floatValue) {
                        set기타매입(floatValue.toString());
                      } else {
                        set기타매입('');
                      }
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <ListBox>
                    <Fragment>
                      <Box>
                        <Chip
                          label={`${income.start_date}~${income.end_date}`}
                          size="small"
                          color="primary"
                          icon={<DateRangeIcon />}
                        />
                      </Box>
                      <ListBox>
                        <Fragment>
                          <Chip
                            label="기타 매입"
                            variant="outlined"
                            color="primary"
                            size="small"
                          />
                          종이 세금계산서, 종이 계산서, 간이 영수증 매입의 합계
                        </Fragment>
                      </ListBox>
                    </Fragment>
                  </ListBox>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>종합소득금액</Typography>
            <Typography>{종합소득금액.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>매출</TableCell>
                <TableCell align="right">{매출.toLocaleString()}원</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>비용</TableCell>
                <TableCell align="right">
                  {(-비용).toLocaleString()}원
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>과세표준</Typography>
            <Typography>{과세표준.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>종합소득금액</TableCell>
                <TableCell align="right">
                  {종합소득금액.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>기본공제</TableCell>
                <TableCell align="right">
                  {기본공제.toLocaleString()}원
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>산출세액</Typography>
            <Typography>{산출세액.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>과세표준</TableCell>
                <TableCell align="right">
                  {과세표준.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>세율</TableCell>
                <TableCell align="right">{세율 * 100}%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded>
        <AccordionSummary>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>종합소득세</Typography>
            <Typography>{결정세액.toLocaleString()}원</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>산출세액</TableCell>
                <TableCell align="right">
                  {산출세액.toLocaleString()}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>표준세액공제</TableCell>
                <TableCell align="right">
                  {표준세액공제.toLocaleString()}원
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}

export default Income;
